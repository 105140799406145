import React from 'react';
import CustomButton from '../CustomButton';

interface ActionButtonsProps {
  handleLogout: () => void;
  showGetAppButton: boolean;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  handleLogout,
  showGetAppButton,
}) => {
  return (
    <div className="flex flex-col justify-center content-center col-span-2 mt-4 md:mt-0 space-y-4 ">
      <CustomButton
        onClick={handleLogout}
        variant="contained"
        color="error"
        className="w-full md:w-auto"
      >
        Log Out
      </CustomButton>

      {/* New Static Green Button */}
      <CustomButton
        onClick={() => console.log('Green Button Clicked')}
        variant="contained"
        color="success"
        className="w-full md:w-auto"
      >
        Join / Invite to Organization
      </CustomButton>

      {showGetAppButton && (
        <CustomButton
          onClick={() => window.location.href = '/install-app'}
          variant="contained"
          color="primary"
          className="w-full md:w-auto"
        >
          Get the App
        </CustomButton>
      )}
    </div>
  );
};

export default ActionButtons;
