import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Page from "../../components/Page";
import VerificationMessage from "../../components/VerifyEmail/VerificationMessage";
import ResendVerificationButton from "../../components/VerifyEmail/ResendVerificationButton";
import { checkEmailVerified } from "../../services/loginFunctions";

const VerifyEmailPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    checkEmailVerified(navigate);
  }, [navigate]);

  return (
    <Page className="mx-4">
      <div className="text-center mt-6">
        <h1 className="text-2xl mt-8 font-bold">Verify Your Email</h1>
        <VerificationMessage />
        <ResendVerificationButton />
      </div>
    </Page>
  );
};

export default VerifyEmailPage;
