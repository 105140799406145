import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Page from "../../components/Page";
import { loginUser } from "../../services/loginFunctions";
import SignInHeader from "../../components/SignIn/SignInHeader";
import SignInMessage from "../../components/SignIn/SignInMessage";
import SignInForm from "../../components/SignIn/SignInForm";

const SignInPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("verificationSent") === "true") {
      setMessage(
        "A verification email has been sent. Please check your inbox and verify your email before logging in."
      );
    } else if (params.get("verified") === "true") {
      setMessage("Your email has been verified! Please log in.");
    }
  }, [location]);

  return (
    <Page className="mx-4">
      <SignInHeader />
      <div className="mt-10 mx-auto max-w-md">
        <SignInMessage message={message} />
        <SignInForm loginUser={loginUser} navigate={navigate} setMessage={setMessage} />
        <p className="mt-8 text-gray-600 text-sm">
          Don't have an account?{" "}
          <Link to="/register" className="text-blue-500 hover:text-blue-700">
            Click here to register.
          </Link>
        </p>
      </div>
    </Page>
  );
};

export default SignInPage;
