// CustomButton.tsx
import React from "react";
import Button from "@mui/material/Button";

interface CustomButtonProps {
  children: React.ReactNode;
  icon?: React.ReactNode;
  iconPosition?: "left" | "right";
  variant: "text" | "contained" | "outlined";
  color: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  fontSize?: string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean; // Add disabled prop
}

const CustomButton: React.FC<CustomButtonProps> = ({
  children,
  icon,
  iconPosition = "left",
  variant,
  color,
  onClick,
  className = "",
  fontSize = "0.85rem",
  type = "button",
  disabled = false, // Add default value for disabled
}) => {
  const startIcon = iconPosition === "left" ? icon : undefined;
  const endIcon = iconPosition === "right" ? icon : undefined;

  return (
    <Button
      type={type}
      variant={variant}
      color={color}
      onClick={onClick}
      className={className}
      startIcon={startIcon}
      endIcon={endIcon}
      sx={{
        fontSize: fontSize,
      }}
      disabled={disabled} // Apply disabled prop
    >
      {children}
    </Button>
  );
};

export default CustomButton;



