import React, { useState, useEffect } from "react";
import CustomButton from "../CustomButton";
import { resendVerificationEmail } from "../../services/loginFunctions";
import CircularProgress from "@mui/material/CircularProgress";

const MAX_RESENDS_PER_DAY = 5;

const ResendVerificationButton: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [cooldown, setCooldown] = useState(false);
  const [message, setMessage] = useState("");
  const [resendCount, setResendCount] = useState(0);

  useEffect(() => {
    const now = new Date().getTime();
    const resends = JSON.parse(localStorage.getItem("resends") || "[]");
    const recentResends = resends.filter(
      (timestamp: number) => now - timestamp < 24 * 60 * 60 * 1000
    );
    setResendCount(recentResends.length);
    localStorage.setItem("resends", JSON.stringify(recentResends));
  }, []);

  const handleResendVerificationEmail = async () => {
    if (resendCount >= MAX_RESENDS_PER_DAY) {
      alert("You have reached the maximum number of resend attempts for today.");
      return;
    }

    setLoading(true);
    setMessage("");
    try {
      await resendVerificationEmail();
      setMessage("Verification email sent! Please check your inbox.");
      setCooldown(true);
      setLoading(false);

      // Update resend count in local storage
      const now = new Date().getTime();
      const resends = JSON.parse(localStorage.getItem("resends") || "[]");
      resends.push(now);
      localStorage.setItem("resends", JSON.stringify(resends));
      setResendCount(resends.length);

      // Show message for 10 seconds, then allow resend
      setTimeout(() => {
        setMessage("");
        setCooldown(false);
      }, 20000);
    } catch (error) {
      console.error("Error resending verification email:", error);
      alert(
        (error as { message?: string }).message ||
          "Failed to resend verification email."
      );
      setLoading(false);
    }
  };

  return (
    <div className="text-center">
      <CustomButton
        onClick={handleResendVerificationEmail}
        variant="contained"
        color="primary"
        fontSize="12px"
        disabled={cooldown || loading || resendCount >= MAX_RESENDS_PER_DAY}
      >
        {loading ? <CircularProgress size={24} /> : "Resend Verification Email"}
      </CustomButton>
      {message && <p className="mt-2">{message}</p>}
      {resendCount >= MAX_RESENDS_PER_DAY && (
        <p className="mt-2 text-red-500">
          You have reached the maximum number of resend attempts for today.
        </p>
      )}
    </div>
  );
};

export default ResendVerificationButton;
