import React from "react";
import { LinearProgress } from "@mui/material";
import { Link } from "react-router-dom";

interface BidStatisticsProps {
  userLevel: string;
  levelProgress: number;
  totalBidsCreated: number;
  totalBidsSent: number;
  totalBidsAccepted: number;
}

const BidStatistics: React.FC<BidStatisticsProps> = ({
  userLevel,
  levelProgress,
  totalBidsCreated,
  totalBidsSent,
  totalBidsAccepted,
}) => {
  return (
    <div className="mt-8 p-6 bg-gray-100 rounded-lg  max-w-[1000px] shadow-md">
      <h3 className="text-lg md:text-start md:text-2xl font-semibold text-center mb-4">
        Bid Statistics
      </h3>
      <div className="mb-4">
        <h4 className="text-md font-semibold">Experience Points</h4>
        <p className="text-md font-semibold">Level: {userLevel}</p>
        <LinearProgress
          variant="determinate"
          value={levelProgress}
          sx={{ height: 10, borderRadius: 5, width: 300}}
        />
        <p className="mt-2 text-sm text-gray-600">
          Earn more experience points by creating and sending bids.
        </p>
      </div>

      <div className="md:flex  md:justify-around">

      <p className="mb-2 text-sm text-gray-600">
        <strong>Total Bids Created:</strong> {totalBidsCreated}
      </p>
      <p className="mb-2 text-sm text-gray-600">
        <strong>Total Bids Sent:</strong> {totalBidsSent}
      </p>
      <p className="mb-4 text-sm text-gray-600">
        <strong>Total Bids Accepted:</strong> {totalBidsAccepted}
      </p>



      </div>
      <p className="text-sm text-black text-center">
        See how you stack up against the competition!{" "}
        <Link to="/leaderboard" className="text-blue-500 hover:text-blue-700">
          Click here
        </Link>{" "}
        to view the leaderboard.
      </p>
    </div>
  );
};

export default BidStatistics;
