import React from "react";
import Page from "../../components/Page";
import { getDeviceType } from "../../utils/getDeviceType";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import IOSInstructions from "../../components/InstallApp/IOSInstructions";
import AndroidInstructions from "../../components/InstallApp/AndroidInstructions";

const InstallAppPage = () => {
  const deviceType = getDeviceType();

  return (
    <Page className="mx-4 mt-4">
      <Typography variant="h5" className="px-1 font-bold text-center mb-6">
        Install Trade Forge on Your Home Screen
      </Typography>
      {deviceType === "ios" ? (
        <IOSInstructions />
      ) : (
        <AndroidInstructions />
      )}
      <div className="mt-8 flex justify-center">
        <Button variant="contained" color="primary" onClick={() => window.history.back()}>
          Go Back
        </Button>
      </div>
    </Page>
  );
};

export default InstallAppPage;
