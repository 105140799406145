import React, { forwardRef } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { MdOutlineClose } from "react-icons/md";

interface CustomInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  showClearIcon?: boolean;
  type?: string;
  variantType?: "outlined" | "standard" | "filled";
  id?: string;
  name?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  style?: React.CSSProperties;
}

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
  (
    {
      value,
      onChange,
      placeholder,
      className,
      showClearIcon = false,
      type = "text",
      variantType = "outlined",
      id,
      name,
      onKeyDown,
      onBlur,
      onClick,
      onFocus,
      autoComplete,
      style,
    },
    ref
  ) => {
    const handleClear = () => {
      const event = {
        target: { value: "", name: name || "" },
      } as React.ChangeEvent<HTMLInputElement>;
      onChange(event);
    };

    return (
      <TextField
        variant={variantType}
        value={value}
        onChange={onChange}
        label={placeholder}
        className={className}
        type={type}
        id={id}
        name={name}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          startAdornment:
            type === "number" && name === "unitCost" ? (
              <InputAdornment position="start">$</InputAdornment>
            ) : undefined,
          endAdornment:
            showClearIcon && value ? (
              <InputAdornment position="end">
                <IconButton onClick={handleClear} edge="end">
                  <MdOutlineClose />
                </IconButton>
              </InputAdornment>
            ) : undefined,
          inputRef: ref,
          style: { height: "44px" },
        }}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onClick={onClick}
        onFocus={onFocus}
        autoComplete={autoComplete}
        style={{ ...style, height: "44px" }}
        inputProps={{
          style: { padding: "0px 4px", fontSize: "14px", height: "44px" },
        }}
      />
    );
  }
);

export default CustomInput;
