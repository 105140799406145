import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import { useAuth } from "../../../contexts/AuthContext";
import { useProjects } from "../../../contexts/ProjectContext";
import { createProjectAndEstimate } from "../../../services/estimateFunctions";
import ProjectHeader from "../../../components/ProjectHeader";
import ProjectDetailsForm from "../../../components/ProjectDetailsPageComponents/ProjectDetailsForm";
import { Project } from "../../../types/Project";

const MAX_PROJECTS = 5; // Maximum allowed active projects

const ProjectDetailsPage: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { inProgressProject, setInProgressProject, setActiveJobs, setCurrentProject, activeJobs } = useProjects();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Define loading state
  const [error, setError] = useState<string | null>(null); // Define error state

  useEffect(() => {
    if (!inProgressProject.workType) {
      setInProgressProject((prev: Project) => ({ ...prev, workType: "commercial" }));
    }
  }, [inProgressProject, setInProgressProject]);

  const handleInputChange = (name: string, value: string) => {
    setInProgressProject((prev: Project) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCreateProjectAndEstimate = async () => {
    if (!currentUser) {
      console.error("You must be logged in to create a project.");
      return;
    }

    // Check if the user is online
    if (!navigator.onLine) {
      alert('You are currently offline. Please connect to the internet to create a project.');
      return;
    }

    // Check if projects are still loading
    if (loading) {
      alert('Projects are still loading. Please wait.');
      return;
    }

    // Handle error scenario
    if (error) {
      alert('Unable to load projects. Please check your connection.');
      return;
    }

    // Check if the user has reached the project limit
    const totalProjects = activeJobs.length;
    if (totalProjects >= MAX_PROJECTS) {
      setDialogOpen(true); // Show dialog if user has reached project limit
      return;
    }

    try {
      setLoading(true); // Set loading while creating the project
      const projectData = { ...inProgressProject, projectStatus: "In Progress" };
      const projectId = await createProjectAndEstimate(projectData, currentUser.uid);
      const newProject: Project = { id: projectId, ...projectData };
      setActiveJobs((prevJobs: Project[]) => [...prevJobs, newProject]);
      setCurrentProject(newProject);
      navigate(`/project-home/${projectId}`);
    } catch (error) {
      console.error("Error adding project:", error);
      setError("Error creating project.");
    } finally {
      setLoading(false); // Reset loading after the process
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <div className="relative min-h-screen">
      <ProjectHeader
        title="Project Details"
        leftIcon="arrow"
        rightIcon="close"
        onLeftClick={() => navigate(-1)}
        onRightClick={() => navigate("/")}
      />
      <div className="flex flex-col mx-6 pt-[8rem] space-y-8 my-4">
        <ProjectDetailsForm
          projectData={inProgressProject}
          onChange={handleInputChange}
          onSubmit={handleCreateProjectAndEstimate}
        />
      </div>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Project Limit Reached</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have reached the maximum limit of 5 active projects. Please
            complete or delete an existing project before starting a new one.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProjectDetailsPage;
