import React, { useState } from "react";
import { MdCheckCircleOutline } from "react-icons/md";
import Modal from "./Modal";

interface StatusComponentProps {
  status: string;
  onUpdateStatus: (newStatus: string) => void;
}

const statuses = [
  {
    label: "BID IN PROGRESS",
    value: "In Progress",
    bgColor: "bg-yellow-300",
    subcategories: [
      { label: "INCOMPLETE", value: "Incomplete", bgColor: "bg-gray-300" },
      { label: "COMPLETE", value: "Complete", bgColor: "bg-yellow-400" },
    ],
  },
  {
    label: "IN REVIEW",
    value: "In Review",
    bgColor: "bg-orange-500 text-white",
    subcategories: [
      { label: "NEED PRICE", value: "Needs Pricing", bgColor: "bg-gray-300" },
      { label: "COMPLETE", value: "Complete", bgColor: "bg-yellow-400" },
    ],
  },
  {
    label: "SENT TO CLIENT",
    value: "Sent to Client",
    bgColor: "bg-blue-800 text-white",
    subcategories: [
      { label: "INCOMPLETE", value: "Incomplete", bgColor: "bg-gray-300" },
      { label: "COMPLETE", value: "Complete", bgColor: "bg-yellow-400" },
    ],
  },
  {
    label: "ACCEPTED: ",
    value: "Accepted: ",
    bgColor: "bg-green-500 text-white",
    subcategories: [
      { label: "INCOMPLETE", value: "Incomplete", bgColor: "bg-gray-300" },
      { label: "COMPLETE", value: "Complete", bgColor: "bg-yellow-400" },
    ],
  },
];

const StatusComponent: React.FC<StatusComponentProps> = ({
  status,
  onUpdateStatus,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(status);
  const [selectedSubStatus, setSelectedSubStatus] = useState<string | null>(
    null
  );

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedSubStatus(null); // Reset subcategory when modal closes
  };

  const handleStatusChange = (newStatus: string) => {
    setSelectedStatus(newStatus);
    const statusWithSubcategories = statuses.find(s => s.value === newStatus);
    if (!statusWithSubcategories?.subcategories) {
      onUpdateStatus(newStatus);
      handleCloseModal();
    }
  };

  const handleSubStatusChange = (newSubStatus: string) => {
    const fullStatus = `${selectedStatus}: ${newSubStatus}`;
    onUpdateStatus(fullStatus);
    handleCloseModal();
  };

  const currentStatus = statuses.find((s) => s.value === selectedStatus);

  return (
    <>
      <div
        className={`flex items-center w-fit px-2 border rounded-md cursor-pointer ${
          currentStatus?.bgColor || "bg-gray-200"
        }`}
        onClick={handleOpenModal}
      >
        <MdCheckCircleOutline className="text-md mr-2" />
        <div className="w-full">
          <p className="text-sm font-semibold">
            {status}
            {selectedSubStatus ? `: ${selectedSubStatus}` : ""}
          </p>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        className="h-[80%]"
      >
        <h2 className="text-lg font-bold mb-2">Update Status</h2>
        <div className="space-y-5">
          {statuses.map((s) => (
            <div key={s.value}>
              <div
                className="flex items-center cursor-pointer"
                onClick={() => handleStatusChange(s.value)}
              >
                <input
                  type="radio"
                  name="status"
                  value={s.value}
                  checked={selectedStatus === s.value}
                  readOnly
                  className="mr-2"
                />
                <span
                  className={`px-4 shadow-md ml-4 py-1 rounded ${s.bgColor}`}
                >
                  {s.label}
                </span>
              </div>

              {selectedStatus === s.value && s.subcategories && (
                <div className="ml-8 space-y-2 mt-2">
                  {s.subcategories.map((sub) => (
                    <div
                      key={sub.value}
                      className="flex items-center cursor-pointer"
                      onClick={() => handleSubStatusChange(sub.value)}
                    >
                      <input
                        type="radio"
                        name="substatus"
                        value={sub.value}
                        checked={selectedSubStatus === sub.value}
                        readOnly
                        className="mr-2"
                      />
                      <span
                        className={`px-4 shadow-md ml-4 py-1 rounded ${sub.bgColor}`}
                      >
                        {sub.label}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default StatusComponent;
