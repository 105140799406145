

import React from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlineClose } from "react-icons/ai";

interface ProjectHeaderProps {
  title: string;
  leftIcon?: "arrow" | "close";
  rightIcon?: "arrow" | "none" | "close";
  onLeftClick?: () => void;
  onRightClick?: () => void;
}

const ProjectHeader: React.FC<ProjectHeaderProps> = ({
  title,
  leftIcon = "arrow",
  rightIcon = "arrow",
  onLeftClick,
  onRightClick,
}) => {
  const navigate = useNavigate();

  const handleLeftClick = () => {
    if (onLeftClick) {
      onLeftClick();
    } else {
      navigate(-1);
    }
  };

  const handleRightClick = () => {
    if (onRightClick) {
      onRightClick();
    }
  };

  return (
    <div className="absolute top-0 left-0 right-0 z-10">
      <div className="flex justify-between p-4">
        {leftIcon === "arrow" ? (
          <AiOutlineArrowLeft
            onClick={handleLeftClick}
            className="text-2xl cursor-pointer"
          />
        ) : (
          <AiOutlineClose
            onClick={handleLeftClick}
            className="text-2xl cursor-pointer"
          />
        )}
        {rightIcon === "arrow" ? (
          <AiOutlineArrowRight
            onClick={handleRightClick}
            className="text-2xl cursor-pointer"
          />
        ) : rightIcon === "close" ? (
          <AiOutlineClose
            onClick={handleRightClick}
            className="text-2xl cursor-pointer"
          />
        ) : (
          <div className="text-2xl cursor-pointer" />
        )}
      </div>
      <h1 className="text-3xl text-left ml-[1rem]">{title}</h1>
    </div>
  );
};

export default ProjectHeader;
