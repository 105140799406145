import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import CustomButton from "../../components/CustomButton";

interface DeleteDialogProps {
  open: boolean;
  handleClose: () => void;
  handleDelete: () => void;
}

const DeleteDialog: React.FC<DeleteDialogProps> = ({ open, handleClose, handleDelete }) => (
  <Dialog open={open} onClose={handleClose}>
    <DialogTitle>Delete Project</DialogTitle>
    <DialogContent>
      <DialogContentText>Are you sure you want to delete this project? This action cannot be undone.</DialogContentText>
    </DialogContent>
    <DialogActions>
      <CustomButton onClick={handleClose} color="primary" variant="outlined">Cancel</CustomButton>
      <CustomButton onClick={handleDelete} color="error" variant="contained">Delete</CustomButton>
    </DialogActions>
  </Dialog>
);

export default DeleteDialog;
