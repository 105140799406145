import React, { useState, useEffect } from "react";
import CustomButton from "../CustomButton";
import CustomInput from "../CustomInput";

interface AddressFormProps {
  initialData?: any;
  onConfirm: (data: any) => void;
}

const AddressForm: React.FC<AddressFormProps> = ({ initialData, onConfirm }) => {
  const [projectName, setProjectName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");

  useEffect(() => {
    if (initialData) {
      setProjectName(initialData.projectName || "");
      const addressParts = (initialData.address || "").split(", ");
      setAddressLine1(addressParts[0] || "");
      setAddressLine2(addressParts.length > 4 ? addressParts[1] : "");
      setCity(addressParts.length > 4 ? addressParts[2] : addressParts[1] || "");
      setState(addressParts.length > 4 ? addressParts[3] : addressParts[2] || "");
      setZip(addressParts.length > 4 ? addressParts[4] : addressParts[3] || "");
    }
    console.log("Loaded initialData from props:", initialData);
  }, [initialData]);

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>, key: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setter(event.target.value);
      console.log(`${key} changed:`, event.target.value);
    };

  const handleSubmit = () => {
    onConfirm({
      projectName,
      addressLine1,
      addressLine2,
      city,
      state,
      zip,
    });
  };

  return (
    <div className="flex flex-col mx-6 pt-[8rem] space-y-8 my-4">
      <CustomInput
        value={projectName}
        onChange={handleInputChange(setProjectName, "projectName")}
        placeholder="Project Name"
        showClearIcon={true}
        className="w-full"
        variantType="standard"
      />
      <CustomInput
        value={addressLine1}
        onChange={handleInputChange(setAddressLine1, "addressLine1")}
        placeholder="Address Line 1"
        showClearIcon={true}
        className="w-full"
        variantType="standard"
      />
      <CustomInput
        value={addressLine2}
        onChange={handleInputChange(setAddressLine2, "addressLine2")}
        placeholder="Address Line 2"
        showClearIcon={true}
        className="w-full"
        variantType="standard"
      />
      <div className="w-full pb-6">
        <div className="flex justify-around">
          <div className="w-[50%]">
            <CustomInput
              value={city}
              onChange={handleInputChange(setCity, "city")}
              placeholder="City"
              showClearIcon={true}
              className="w-full"
              variantType="standard"
            />
          </div>

          <div className="w-[20%]">
            <CustomInput
              value={state}
              onChange={handleInputChange(setState, "state")}
              placeholder="State"
              showClearIcon={true}
              className="w-full"
              variantType="standard"
            />
          </div>

          <div className="w-[20%]">
            <CustomInput
              value={zip}
              onChange={handleInputChange(setZip, "zip")}
              placeholder="Zip Code"
              showClearIcon={true}
              className="w-full"
              variantType="standard"
            />
          </div>
        </div>
      </div>
      <div className="pt-6">
        <CustomButton
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          className="w-[100%]"
        >
          Confirm Address
        </CustomButton>
      </div>
    </div>
  );
};

export default AddressForm;
