
import React, { useState, useEffect } from "react";
import Page from "../../components/Page";
import { signOut } from "firebase/auth";
import { auth, db } from "../../services/firebase-config";
import { useAuth } from "../../contexts/AuthContext";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import SnackbarMessage from "../../components/SnackBarMessage";
import { getDeviceType, isStandalone } from "../../utils/getDeviceType";
import { getBidLevel, getLevelProgress } from "../../utils/getBidLevel";
import { scrollToTop } from "../../utils/scrollUtil";
import { uploadProfilePicture } from "../../services/loginFunctions";
import BidStatistics from "../../components/BidStatistics/BidStatistics";
import ProfileHeader from "../../components/ProfileHeader/ProfileHeader";
import UserInfoForm from "../../components/UserInfoForm/UserInfoForm";
import UploadProgress from "../../components/UploadProgress/UploadProgress";
import ActionButtons from "../../components/ActionButtons/ActionButtons";
import DiscardChangesDialog from "../../components/DiscardChangesDialog/DiscardChangesDialog";

const AccountPage: React.FC = () => {
  const { currentUser, userData, setUserData } = useAuth();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showDiscardDialog, setShowDiscardDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    firstName: userData?.firstName || "",
    lastName: userData?.lastName || "",
    companyName: userData?.companyName || "",
    jobTitle: userData?.jobTitle || "",
    birthday: userData?.birthday || "",
  });
  const [originalData, setOriginalData] = useState(formData);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        const userRef = doc(db, "users", currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
      }
    };

    fetchUserData();
  }, [currentUser, setUserData]);

  const totalBidsCreated = userData?.totalBidsCreated || 0;
  const totalBidsSent = userData?.totalBidsSent || 0;
  const totalBidsAccepted = userData?.totalBidsAccepted || 0;
  const experiencePoints = totalBidsCreated * 10;

  const userLevel: string = getBidLevel(totalBidsCreated);
  const levelProgress: number = getLevelProgress(totalBidsCreated);

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setOriginalData(formData);
  };

  const handleCancelEdit = () => {
    if (JSON.stringify(formData) !== JSON.stringify(originalData)) {
      setShowDiscardDialog(true);
    } else {
      setIsEditing(false);
    }
  };

  const discardChanges = () => {
    setFormData(originalData);
    setIsEditing(false);
    setShowDiscardDialog(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSaveChanges = async () => {
    if (currentUser) {
      try {
        const userRef = doc(db, "users", currentUser.uid);
        await updateDoc(userRef, {
          firstName: formData.firstName,
          lastName: formData.lastName,
          companyName: formData.companyName,
          jobTitle: formData.jobTitle,
          birthday: formData.birthday,
        });
        setUserData({ ...userData, ...formData });
        setOpenSnackbar(true);
        setIsEditing(false);
        scrollToTop();
      } catch (error) {
        console.error("Error updating profile:", error);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleUploadProfilePicture = async () => {
    if (currentUser && selectedFile) {
      setLoading(true);
      try {
        const downloadURL = await uploadProfilePicture(
          currentUser.uid,
          selectedFile,
          setUploadProgress
        );
        const userRef = doc(db, "users", currentUser.uid);
        await updateDoc(userRef, {
          profilePic: downloadURL,
        });
        setUserData({ ...userData, profilePic: downloadURL });
        setSelectedFile(null);
        setUploadProgress(0);
        setOpenSnackbar(true);
      } catch (error) {
        console.error("Error uploading profile picture:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const showGetAppButton = !isStandalone() && getDeviceType() !== "desktop";

  return (
    <Page className="mx-4 mb-6 md:grid md:grid-cols-8 md:gap-8 md:mx-12 md:w-full">
      {/* Profile Section on the left */}
      <div className=" col-span-2 items-start">
        <ProfileHeader
          userData={userData}
          loading={loading}
          handleFileChange={handleFileChange}
          className="md:w-full"
        />
        <div className="mt-8 w-full">
          <UserInfoForm
            formData={formData}
            isEditing={isEditing}
            handleInputChange={handleInputChange}
            handleCancelEdit={handleCancelEdit}
            handleSaveChanges={handleSaveChanges}
            handleEditClick={handleEditClick}
          />
        </div>
      </div>

      {/* Statistics Section on the right */}
      <div className="mt-8 md:mt- md:col-span-6">
        <BidStatistics
          userLevel={userLevel}
          levelProgress={levelProgress}
          totalBidsCreated={totalBidsCreated}
          totalBidsSent={totalBidsSent}
          totalBidsAccepted={totalBidsAccepted}
        />
      </div>

      <ActionButtons
        handleLogout={handleLogout}
        showGetAppButton={showGetAppButton}
      />
    </Page>
  );
};

export default AccountPage;
