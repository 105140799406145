import React from "react";
import { IoIosArrowBack } from "react-icons/io";

import ProjectNavigation from "../../components/ProjectNavigation";

interface HeaderProps {
  navigate: (path: string) => void;
  projectId: string | undefined;
}

const Header: React.FC<HeaderProps> = ({ navigate, projectId }) => (
  <div className="sticky top-0 left-0 right-0 z-10 bg-white">
    <div className="flex justify-between p-4">
      <IoIosArrowBack
        onClick={() => navigate(-1 as unknown as string)}
        className="text-2xl cursor-pointer"
      />
      <p
        className="text-blue-500 cursor-pointer text-xl"
        onClick={() => navigate(`/project-home/${projectId}`)}
      >
        Back To Project
      </p>
    </div>
    <h1 className="text-3xl text-start ml-[2.5rem]">Blueprints</h1>
    <ProjectNavigation />
  </div>
);

export default Header;
