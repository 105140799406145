import React from 'react';
import Modal from '../../components/Modal';
import { User } from '../../types/User';

interface UserModalProps {
  user: User | null;
  topUsers: User[];
  onClose: () => void;
}

const UserModal: React.FC<UserModalProps> = ({ user, topUsers, onClose }) => (
  <Modal isOpen={!!user} onClose={onClose}>
    {user && (
      <div className="px-2 py-1">
        <h2 className="text-xl font-bold">
          TRADE FORGE'S
          <span className="ml-2 underline underline-offset-2">NO. {topUsers.indexOf(user) + 1}</span> ESTIMATOR!
        </h2>
        <div className="flex items-center justify-start mt-6">
          <img
            src={user.profilePic || "https://via.placeholder.com/150"}
            alt="Profile"
            className="w-20 h-20 rounded-full object-cover mr-8"
          />
          <div className="space-y-1">
            <h3 className="text-xl mb-4 font-semibold">{user.firstName} {user.lastName}</h3>
            <p className="text-sm text-gray-500">Company: {user.companyName}</p>
            <p className="text-sm text-gray-500">Position: {user.jobTitle}</p>
          </div>
        </div>
        {user.motto && (
          <div className="mt-4">
            <strong className="text-sm">Motto:</strong>
            <p>{user.motto}</p>
          </div>
        )}
      </div>
    )}
  </Modal>
);

export default UserModal;
