import React from 'react';
import { AiOutlinePlus } from 'react-icons/ai';

interface PhotoUploadButtonProps {
  onClick: () => void;
}

const PhotoUploadButton: React.FC<PhotoUploadButtonProps> = ({ onClick }) => (
  <div
    className="flex items-center w-full max-w-2xl p-4 rounded-md cursor-pointer"
    onClick={onClick}
  >
    <div className="w-[32px] h-[32px] border-dotted border-2 border-blue-400 flex justify-center items-center mr-5">
      <AiOutlinePlus className="text-2xl text-blue-500" />
    </div>
    <p className="text-blue-500">Photo</p>
  </div>
);

export default PhotoUploadButton;
