import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const RequireEmailVerification: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { currentUser } = useAuth();

  if (currentUser && !currentUser.emailVerified) {
    return <Navigate to="/verify-email" />;
  }

  return <>{children}</>;
};

export default RequireEmailVerification;
