import React from "react";
import { IoIosPhonePortrait } from "react-icons/io"; 
import { MdIosShare, MdOutlineAddBox, MdShare } from "react-icons/md"; 
import { Typography } from "@mui/material";

const IOSInstructions = () => (
  <div className="text-center">
    <IoIosPhonePortrait className="text-5xl mx-auto text-blue-500 mt-4" />
    <Typography variant="h6" className="pt-4">Follow these steps for iPhone:</Typography>
    <ol className="list-decimal list-inside bg-gray-100 p-4 rounded-lg shadow space-y-5 mt-4">
      <li className="flex items-center"><MdIosShare className="text-xl mr-2" /> Tap the Share button in Safari.</li>
      <li className="flex items-center"><MdOutlineAddBox className="text-xl mr-2" /> Tap the 'Add to Home Screen' icon.</li>
      <li className="flex items-center"><MdShare className="text-xl mr-2" /> Tap Add.</li>
    </ol>
  </div>
);

export default IOSInstructions;
