import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
  getDocs,
  collection,
} from "firebase/firestore";
import { db } from "../../services/firebase-config";
import Page from "../../components/Page";
import { CircularProgress } from "@mui/material";
import NoteGenerator from "../../components/NoteGenerator";
import ProjectButtons from "../../components/ProjectButtons";
import SnackbarNotification from "../../components/ProjectHome/SnackbarContainer";
import ProjectDetailsContainer from "../../components/ProjectHome/ProjectDetails";
import DeleteDialog from "../../components/ProjectHome/DeleteDialog";
import HeaderSection from "../../components/ProjectHome/HeaderSection";
import { useProjects } from "../../contexts/ProjectContext";
import { Note, Project } from "../../types/Project";

const ProjectHomePage: React.FC = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { setCurrentProject, currentProject } = useProjects();
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [notesOpen, setNotesOpen] = useState(false);
  const [notes, setNotes] = useState<Note[]>([]);
  const [editableField, setEditableField] = useState<string | null>(null);
  const [tempValue, setTempValue] = useState<string>("");
  const [isStarred, setIsStarred] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [estimateCount, setEstimateCount] = useState(0);
  const [documentsCount, setDocumentsCount] = useState(0);
  const [materialsCount, setMaterialsCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // Add anchorEl state
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const fetchProject = async () => {
      if (!projectId) {
        console.error("No project ID found in URL");
        return;
      }

      try {
        const projectDoc = await getDoc(doc(db, "projects", projectId));
        if (projectDoc.exists()) {
          const projectData = {
            id: projectDoc.id,
            ...projectDoc.data(),
          } as Project;
          setCurrentProject(projectData); // Set the project in context
          setNotes(projectData.notes || []);

          // Fetching counts
          const estimatesSnapshot = await getDocs(
            collection(db, "projects", projectId, "estimates")
          );
          setEstimateCount(estimatesSnapshot.size);

          const documentsSnapshot = await getDocs(
            collection(db, "projects", projectId, "documents")
          );
          setDocumentsCount(documentsSnapshot.size);

          const swatchesSnapshot = await getDocs(
            collection(db, "projects", projectId, "swatches")
          );
          setMaterialsCount(swatchesSnapshot.size);
        } else {
          console.error("No project found with the given ID");
        }
      } catch (error) {
        console.error("Error fetching project:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [projectId, setCurrentProject]);

  useEffect(() => {
    if (editableField && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [editableField]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget); // Set the anchor element
  };

  const handleMenuClose = () => {
    setAnchorEl(null); // Clear the anchor element
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleNotesOpen = () => {
    setNotesOpen(true);
  };

  const handleNotesClose = () => {
    setNotesOpen(false);
  };

  const handleMaterialsPlusClick = () => {
    navigate(`/finish/${projectId}?addMaterial=true`);
  };
  
  const handleDeleteProject = async () => {
    if (projectId) {
      try {
        await deleteDoc(doc(db, "projects", projectId));
        navigate("/");
      } catch (error) {
        console.error("Error deleting project:", error);
      }
    }
  };

  const handleCreateEstimateClick = () => {
    navigate(`/create-estimate/${projectId}`);
  };

  const handleDocumentsClick = () => {
    navigate(`/blueprints/${projectId}`);
  };

  const handleDocumentsPlusClick = () => {
    navigate(`/blueprints/${projectId}`);
    setTimeout(() => {
      document.getElementById("file-upload")?.click();
    }, 0);
  };

  const handleMaterialsClick = () => {
    navigate(`/finish/${projectId}`);
  };

  const handleFieldClick = (fieldName: string, fieldValue: string) => {
    if (editableField !== fieldName) {
      setEditableField(fieldName);
      setTempValue(fieldValue || "");
    }
  };

  const handleFieldBlur = async (fieldName: string) => {
    if (tempValue.trim() !== "" && projectId) {
      try {
        await updateDoc(doc(db, "projects", projectId), {
          [fieldName]: tempValue,
        });
        const updatedProject = {
          ...currentProject,
          [fieldName]: tempValue,
        } as Project;
        setCurrentProject(updatedProject);
      } catch (error) {
        console.error("Error updating project:", error);
      }
    }
    setEditableField(null);
  };

  const handleStarClick = () => {
    setIsStarred(!isStarred);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleUpdateStatus = async (newStatus: string) => {
    if (projectId) {
      try {
        await updateDoc(doc(db, "projects", projectId), {
          projectStatus: newStatus,
        });
        const updatedProject = {
          ...currentProject,
          projectStatus: newStatus,
        } as Project;
        setCurrentProject(updatedProject);
      } catch (error) {
        console.error("Error updating project status:", error);
      }
    }
  };

  const handleDateChange = async (date: Date) => {
    if (projectId) {
      const dateString = date.toISOString().split("T")[0];
      try {
        await updateDoc(doc(db, "projects", projectId), {
          dateReceived: dateString,
        });
        const updatedProject = {
          ...currentProject,
          dateReceived: dateString,
        } as Project;
        setCurrentProject(updatedProject);
      } catch (error) {
        console.error("Error updating project date:", error);
      }
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      handleFieldBlur(editableField!);
      setEditableField(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editableField]);

  const projectToDisplay = currentProject;

  return (
    <Page className="relative min-h-screen">
      <HeaderSection
        navigate={navigate}
        handleMenuOpen={handleMenuOpen}
        handleStarClick={handleStarClick}
        isStarred={isStarred}
        anchorEl={anchorEl} // Pass anchorEl state
        handleMenuClose={handleMenuClose}
        handleDialogOpen={handleDialogOpen}
      />

      <DeleteDialog
        open={dialogOpen}
        handleClose={handleDialogClose}
        handleDelete={handleDeleteProject}
      />

      <NoteGenerator
        projectId={projectId!}
        notes={notes}
        setNotes={setNotes}
        open={notesOpen}
        onClose={handleNotesClose}
      />

      <div className="flex flex-col items-start px-4  " ref={containerRef}>
        {loading ? (
          <div className="flex items-center justify-center w-full h-screen">
            <CircularProgress />
          </div>
        ) : projectToDisplay ? (
          <ProjectDetailsContainer
            project={projectToDisplay}
            editableField={editableField}
            tempValue={tempValue}
            setTempValue={setTempValue}
            inputRef={inputRef}
            handleFieldClick={handleFieldClick}
            handleFieldBlur={handleFieldBlur}
            handleDateChange={handleDateChange}
            handleNotesOpen={handleNotesOpen}
            handleUpdateStatus={handleUpdateStatus}
          />
        ) : (
          <div>No project data available.</div>
        )}
      </div>
      <div className="sm:w-[50%]">
        <ProjectButtons
          onCreateEstimateClick={handleCreateEstimateClick}
          onDocumentsClick={handleDocumentsClick}
          onDocumentsPlusClick={handleDocumentsPlusClick}
          onMaterialsClick={handleMaterialsClick}
          onMaterialsPlusClick={handleMaterialsPlusClick} // Add this line
          estimateStatus={estimateCount}
          documentsStatus={documentsCount}
          materialsStatus={materialsCount}
        />
      </div>

      <SnackbarNotification
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
      />
    </Page>
  );
};

export default ProjectHomePage;
