import React from "react";
import { Snackbar, Alert } from "@mui/material";

interface SnackbarNotificationProps {
  open: boolean;
  handleClose: (event?: React.SyntheticEvent | Event, reason?: string) => void;
}

const SnackbarNotification: React.FC<SnackbarNotificationProps> = ({ open, handleClose }) => (
  <Snackbar
    open={open}
    autoHideDuration={6000}
    onClose={handleClose}
    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
  >
    <Alert onClose={handleClose} className="mb-4" severity="info" sx={{ width: "100%" }}>
      Favorites Coming Soon
    </Alert>
  </Snackbar>
);

export default SnackbarNotification;
