import React from "react";
import CustomButton from "../components/CustomButton";
import { AiOutlinePlus } from "react-icons/ai";

interface ProjectButtonsProps {
  onCreateEstimateClick: () => void;
  onDocumentsClick: () => void;
  onDocumentsPlusClick: () => void;
  onMaterialsClick: () => void;
  onMaterialsPlusClick: () => void;

  estimateStatus?: number;
  documentsStatus?: number;
  materialsStatus?: number;
}

const ProjectButtons: React.FC<ProjectButtonsProps> = ({
  onCreateEstimateClick,
  onDocumentsClick,
  onDocumentsPlusClick,
  onMaterialsPlusClick,

  onMaterialsClick,
  estimateStatus,
  documentsStatus,
  materialsStatus,
}) => {
  return (
    <div className="bg-white border mt-[1rem] mx-4 rounded-lg shadow-lg">
      <div
        onClick={onCreateEstimateClick}
        className="flex items-center justify-between cursor-pointer p-2"
      >
        <p className="text-lg font-semibold flex items-center">
          Estimate
          {estimateStatus !== undefined && estimateStatus > 0 && (
            <span className="ml-6 flex items-center justify-center w-6 h-6 bg-gray-300 rounded-full text-xs">
              {estimateStatus}
            </span>
          )}
        </p>
        <CustomButton
          variant="text"
          color="primary"
          onClick={onCreateEstimateClick}
          className="ml-auto"
        >
          Create
        </CustomButton>
      </div>

      <div
        className="flex items-center justify-between cursor-pointer border-y-2 p-2"
        onClick={onDocumentsClick}
      >
        <p className="text-lg font-semibold flex items-center">
          Documents
          {documentsStatus !== undefined && documentsStatus > 0 && (
            <span className="ml-6 flex items-center justify-center w-6 h-6 bg-gray-300 rounded-full text-xs">
              {documentsStatus}
            </span>
          )}
        </p>
        <CustomButton
          variant="text"
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            onDocumentsPlusClick();
          }}
          className="ml-auto"
        >
          <AiOutlinePlus />
        </CustomButton>
      </div>

      <div
        className="flex items-center justify-between cursor-pointer p-2"
        onClick={onMaterialsClick}
      >
        <p className="text-lg font-semibold flex items-center">
          Materials
          {materialsStatus !== undefined && materialsStatus > 0 && (
            <span className="ml-6 flex items-center justify-center w-6 h-6 bg-gray-300 rounded-full text-xs">
              {materialsStatus}
            </span>
          )}
        </p>
        <CustomButton
          variant="text"
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            onMaterialsPlusClick(); // Navigate to materials and start add mode
          }}
          className="ml-auto"
        >
          <AiOutlinePlus />
        </CustomButton>
      </div>
    </div>
  );
};

export default ProjectButtons;
