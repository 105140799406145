import React from 'react';
import { User } from '../../types/User';
import { getBidLevel } from '../../utils/getBidLevel';

interface TopUsersListProps {
  topUsers: User[];
  onUserClick: (user: User) => void;
}

const TopUsersList: React.FC<TopUsersListProps> = ({ topUsers, onUserClick }) => (
  <div className="mt-5 p-4 bg-gray-100 rounded-lg mx-4 max-w-[550px] ml-auto mr-auto">
    <h3 className="text-lg font-semibold text-center">Most Estimates Created</h3>
    {topUsers.map((user, index) => (
      <div
        key={user.id}
        className="mt-4 p-4 bg-white rounded-lg shadow text-center cursor-pointer"
        onClick={() => onUserClick(user)}
      >
        <h4 className="text-md font-semibold">
          #{index + 1} - {user.firstName} {user.lastName}
        </h4>
        <p className="text-sm text-gray-500">Total Bids Created: {user.totalBidsCreated}</p>
        <p className="text-sm text-gray-500">Level: {getBidLevel(user.totalBidsCreated)}</p>
      </div>
    ))}
  </div>
);

export default TopUsersList;
