
import React from "react";

interface RegisterErrorMessageProps {
  error: string;
}

const RegisterErrorMessage: React.FC<RegisterErrorMessageProps> = ({ error }) => {
  return error ? <p className="text-red-500 text-center">{error}</p> : null;
};

export default RegisterErrorMessage;
