import React from "react";
import { MdAndroid, MdShare, MdAddBox } from "react-icons/md"; 
import { Typography } from "@mui/material";

const AndroidInstructions = () => (
  <div className="text-center">
    <MdAndroid className="text-5xl mx-auto text-green-500 mt-4" />
    <Typography variant="h6" className="pt-4">Follow these steps for Android:</Typography>
    <ol className="list-decimal list-inside bg-gray-100 p-4 rounded-lg shadow space-y-5 mt-4">
      <li className="flex items-center"><MdShare className="text-xl mr-2" /> Tap the Menu button in Chrome.</li>
      <li className="flex items-center"><MdAddBox className="text-lg mr-2" /> Tap the 'Add to Home screen' icon.</li>
      <li className="flex items-center"><MdShare className="text-xl mr-2" /> Tap Add.</li>
    </ol>
  </div>
);

export default AndroidInstructions;
