import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../components/BlueprintPage/Header";
import { AiOutlinePlus, AiOutlineEllipsis, AiOutlineSearch, AiOutlineUp, AiOutlineDown } from "react-icons/ai";
import { collection, getDocs } from "firebase/firestore";
import { SnackbarCloseReason, LinearProgress } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import Modal from "../../../components/Modal";
import CustomInput from "../../../components/CustomInput";
import SnackbarMessage from "../../../components/SnackBarMessage";
import { uploadFile, deleteDocument, updateDocumentName, updatePageName } from "../../../services/estimateFunctions"; // Adjust the import path
import { db } from "../../../services/firebase-config";

const truncate = (str: string, maxLength: number) => {
  return str.length > maxLength ? str.substring(0, maxLength) + "..." : str;
};

const BlueprintsPage: React.FC = () => {
  const navigate = useNavigate();
  const { projectId } = useParams<{ projectId: string }>();
  const [documents, setDocuments] = useState<{ id: string; name: string; url: string; pages: string[]; isLoading: boolean }[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>("");
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [expandedDocIndex, setExpandedDocIndex] = useState<number | null>(null);
  const [menuDocId, setMenuDocId] = useState<string | null>(null);
  const [renameDocId, setRenameDocId] = useState<string | null>(null);
  const [renamePageIndex, setRenamePageIndex] = useState<number | null>(null);
  const [renameValue, setRenameValue] = useState<string>("");

  const MAX_BLUEPRINT_SIZE_MB = 10;

  useEffect(() => {
    if (projectId) {
      const fetchDocuments = async () => {
        const querySnapshot = await getDocs(
          collection(db, "projects", projectId, "documents")
        );
        const docs = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          isLoading: false,
        })) as { id: string; name: string; url: string; pages: string[]; isLoading: boolean }[];
        setDocuments(docs);
      };
      fetchDocuments();
    }
  }, [projectId]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (documents.length >= 2) {
      setSnackbarMessage(
        "Beta version of the app only supports two documents per project. Please delete an existing document to upload a new one."
      );
      setIsSnackbarOpen(true);
      return;
    }
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size > MAX_BLUEPRINT_SIZE_MB * 1024 * 1024) {
        setSnackbarMessage(
          `File size exceeds the maximum limit of ${MAX_BLUEPRINT_SIZE_MB}MB.`
        );
        setIsSnackbarOpen(true);
        return;
      }
      setSelectedFile(file);
      setFileName(file.name);
      setIsUploadModalOpen(true);
    }
  };

  const handleMobileUpload = () => {
    if (documents.length >= 2) {
      setSnackbarMessage(
        "Beta version of the app only supports two documents per project. Please delete an existing document to upload a new one."
      );
      setIsSnackbarOpen(true);
      return;
    }
    document.getElementById("file-upload")?.click();
  };

  const handleUploadConfirm = async () => {
    if (selectedFile && projectId) {
      setDocuments((prevDocs) => [
        ...prevDocs,
        { id: "temp", name: fileName, url: "", pages: [], isLoading: true },
      ]);
      setIsUploadModalOpen(false);
      try {
        await uploadFile(projectId, selectedFile, fileName);
        const querySnapshot = await getDocs(
          collection(db, "projects", projectId, "documents")
        );
        const docs = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          isLoading: false,
        })) as { id: string; name: string; url: string; pages: string[]; isLoading: boolean }[];
        setDocuments(docs);
        setSelectedFile(null);
        setFileName("");
      } catch (error) {
        setSnackbarMessage("Failed to upload document. Please try again.");
        setIsSnackbarOpen(true);
      }
    }
  };

  const handleDeleteDocument = async (docId: string, docName: string) => {
    if (projectId) {
      try {
        await deleteDocument(projectId, docId, docName);
        setDocuments(documents.filter((doc) => doc.id !== docId));
        setIsMenuOpen(false);
      } catch (error) {
        console.error("Error deleting document:", error);
        setSnackbarMessage("Failed to delete document. Please try again.");
        setIsSnackbarOpen(true);
      }
    }
  };

  const handleMenuOpen = (docId: string) => {
    setMenuDocId(docId);
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuDocId(null);
    setIsMenuOpen(false);
  };

  const handleRename = (docId: string, pageIndex: number | null) => {
    setRenameDocId(docId);
    setRenamePageIndex(pageIndex);
    const document = documents.find((doc) => doc.id === docId);
    setRenameValue(pageIndex !== null && document?.pages[pageIndex] ? document.pages[pageIndex] : document?.name || "");
    setIsRenameModalOpen(true);
  };

  const handleRenameConfirm = async () => {
    if (renameDocId && projectId) {
      if (renamePageIndex !== null) {
        // Rename page
        await updatePageName(projectId, renameDocId, renamePageIndex, renameValue);
      } else {
        // Rename document
        await updateDocumentName(projectId, renameDocId, renameValue);
      }

      // Refresh documents
      const querySnapshot = await getDocs(collection(db, "projects", projectId, "documents"));
      const docs = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        isLoading: false,
      })) as { id: string; name: string; url: string; pages: string[]; isLoading: boolean }[];
      setDocuments(docs);

      setRenameDocId(null);
      setRenamePageIndex(null);
      setRenameValue("");
      setIsRenameModalOpen(false);
    }
  };

  const handleSnackbarClose = (
    event: React.SyntheticEvent<any, Event> | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSnackbarOpen(false);
  };

  const handleExpand = (index: number) => {
    setExpandedDocIndex(expandedDocIndex === index ? null : index);
  };

  const handleInputClick = (e: React.FocusEvent<HTMLInputElement>) => {
    const fileName = e.target.value;
    const dotIndex = fileName.lastIndexOf(".");
    if (dotIndex > 0) {
      e.target.setSelectionRange(0, dotIndex);
    } else {
      e.target.select();
    }
  };

  return (
    <div className="relative min-h-screen pt-0">
      <Header navigate={navigate} projectId={projectId} />
      <div className="flex flex-col items-center ml-2 pt-1 space-y-4">
        <div
          className="flex items-center w-full max-w-2xl p-4 rounded-md cursor-pointer text-xl"
          onClick={handleMobileUpload}
        >
          <div className="w-[32px] h-[32px] border-dotted border-2 border-blue-400 flex justify-center items-center mr-5">
            <AiOutlinePlus className="text-2xl text-blue-500" />
          </div>
          <p className="text-blue-500">Document</p>
        </div>
        <input
          id="file-upload"
          type="file"
          accept="application/pdf"
          multiple
          className="hidden"
          onChange={handleFileUpload}
        />
        <div className="w-full max-w-2xl mt-4">
          {documents.length === 0 ? (
            <p className="text-center text-xl text-gray-500">
              Add blueprints and drawings to get started!
            </p>
          ) : (
            <ul className="space-y-2 pr-2 mx-3">
              {documents.map((doc, index) => (
                <li key={index} className="flex flex-col">
                  <div className="flex items-center justify-between">
                    <span className="text-gray-700 font-semibold mt-4 mb-3">
                      {truncate(doc.name, 30)}
                    </span>
                    <AiOutlineEllipsis
                      className="text-2xl cursor-pointer"
                      onClick={() => handleMenuOpen(doc.id)}
                    />
                  </div>
                  {doc.isLoading ? (
                    <div className="flex items-center justify-center">
                      <LinearProgress className="w-full" />
                    </div>
                  ) : (
                    <div className="flex items-center justify-between p-2 bg-gray-100">
                      <div className="flex items-center space-x-2">
                        {expandedDocIndex === index ? (
                          <AiOutlineUp
                            className="cursor-pointer"
                            onClick={() => handleExpand(index)}
                          />
                        ) : (


                          
                          <AiOutlineDown
                            className="cursor-pointer"
                            onClick={() => handleExpand(index)}
                          />
                          
                        )}
                        <span>Pages & Line Items</span>
                        <span className="ml-6 flex items-center justify-center w-6 h-6 bg-gray-300 rounded-full text-xs mt-1">
                          {doc.pages.length}
                        </span>
                      </div>
                      <AiOutlineSearch className="cursor-pointer" />
                    </div>
                  )}
                  {expandedDocIndex === index && !doc.isLoading && (
                    <ul className="space-y-1 text-lg mt-3">
                      {doc.pages.map((page, pageIndex) => (
                        <li
                          key={pageIndex}
                          className="flex items-center py-3 justify-between p-2"
                        >
                          <span className="font-xl">{page}</span>
                          <AiOutlineEllipsis
                            className="text-xl cursor-pointer"
                            onClick={() => handleRename(doc.id, pageIndex)}
                          />
                        </li>
                      ))}
                    </ul>
                  )}
                  {index < documents.length - 1 && (
                    <hr className="border-gray-400 mt-3 pt-8" />
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      <SnackbarMessage
        open={isSnackbarOpen}
        handleClose={handleSnackbarClose}
        message={snackbarMessage}
      />

      <Modal isOpen={isUploadModalOpen} onClose={() => setIsUploadModalOpen(false)}>
        <h2 className="text-lg font-bold mb-2">Rename Document</h2>
        <CustomInput
          value={fileName}
          onChange={(e) => setFileName(e.target.value)}
          placeholder="Document Name"
          className="w-full"
          onFocus={handleInputClick}
        />
        <div className="flex justify-end mt-4">
          <CustomButton
            variant="contained"
            color="primary"
            onClick={handleUploadConfirm}
          >
            Upload
          </CustomButton>
        </div>
      </Modal>

      <Modal isOpen={isRenameModalOpen} onClose={() => setIsRenameModalOpen(false)}>
        <h2 className="text-lg font-bold mb-2">Rename {renamePageIndex !== null ? "Page" : "Document"}</h2>
        <CustomInput
          value={renameValue}
          onChange={(e) => setRenameValue(e.target.value)}
          placeholder={renamePageIndex !== null ? "Page Name" : "Document Name"}
          className="w-full"
        />
        <div className="flex justify-end mt-4">
          <CustomButton
            variant="contained"
            color="primary"
            onClick={handleRenameConfirm}
          >
            Rename
          </CustomButton>
        </div>
      </Modal>

      <Modal isOpen={isMenuOpen} onClose={handleMenuClose}>
        <ul>
          <li
            className="p-2 cursor-pointer hover:bg-gray-100"
            onClick={() => {
              if (menuDocId)
                navigate(
                  `/view-blueprint/${projectId}/${encodeURIComponent(
                    documents.find((doc) => doc.id === menuDocId)?.name || ""
                  )}`
                );
              handleMenuClose();
            }}
          >
            View
          </li>
          <li
            className="p-2 cursor-pointer hover:bg-gray-100"
            onClick={() => {
              if (menuDocId)
                window.open(
                  documents.find((doc) => doc.id === menuDocId)?.url,
                  "_blank"
                );
              handleMenuClose();
            }}
          >
            Download
          </li>
          <li
            className="p-2 cursor-pointer hover:bg-gray-100"
            onClick={() => {
              if (menuDocId)
                handleDeleteDocument(
                  menuDocId,
                  documents.find((doc) => doc.id === menuDocId)?.name || ""
                );
              handleMenuClose();
            }}
          >
            Delete
          </li>
          <li
            className="p-2 cursor-pointer hover:bg-gray-100"
            onClick={() => {
              if (menuDocId)
                handleRename(menuDocId, null);
              handleMenuClose();
            }}
          >
            Rename
          </li>
        </ul>
      </Modal>
    </div>
  );
};

export default BlueprintsPage;
