


// src/contexts/ProjectContext.tsx
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { db } from '../services/firebase-config';
import { getDocs, collection, query, where } from 'firebase/firestore';
import { Project } from '../types/Project';

interface ProjectContextProps {
  projects: Project[];
  fetchProjects: () => Promise<void>;
  inProgressProject: any;
  setInProgressProject: (project: any) => void;
  activeJobs: Project[];
  setActiveJobs: (jobs: Project[] | ((jobs: Project[]) => Project[])) => void;
  currentProject: Project | null;
  setCurrentProject: (project: Project | null) => void;
}

const ProjectContext = createContext<ProjectContextProps | undefined>(undefined);

export const useProjects = () => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error('useProjects must be used within a ProjectProvider');
  }
  return context;
};
export const ProjectProvider = ({ children }: { children: ReactNode }) => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [inProgressProject, setInProgressProject] = useState<any>(null);
  const [activeJobs, setActiveJobs] = useState<Project[]>([]);
  const [currentProject, setCurrentProject] = useState<Project | null>(null);

  const fetchProjects = async () => {
    try {
      console.log("Fetching projects from Firestore...");
      const q = query(collection(db, 'projects'), where('userId', '==', 'testUserId'));
      const querySnapshot = await getDocs(q);
      const projectList: Project[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      })) as Project[];
      setProjects(projectList);
      console.log("Fetched projects from DB:", projectList);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <ProjectContext.Provider value={{
      projects,
      fetchProjects,
      inProgressProject,
      setInProgressProject,
      activeJobs,
      setActiveJobs,
      currentProject,
      setCurrentProject
    }}>
      {children}
    </ProjectContext.Provider>
  );
};
