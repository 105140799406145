import React, { useState, useEffect } from "react";
import CustomInput from "../../components/CustomInput";
import CustomButton from "../../components/CustomButton";
import { Project } from "../../types/Project";

interface ProjectDetailsFormProps {
  projectData: Project;
  onChange: (name: string, value: string) => void;
  onSubmit: () => void;
}

const ProjectDetailsForm: React.FC<ProjectDetailsFormProps> = ({
  projectData,
  onChange,
  onSubmit,
}) => {
  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>, name: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setter(value);
      onChange(name, value);
    };

  const [projectName, setProjectName] = useState(projectData.projectName || "");
  const [contractor, setContractor] = useState(projectData.contractor || "");
  const [generalContractor, setGeneralContractor] = useState(
    projectData.generalContractor || ""
  );
  const [customer, setCustomer] = useState(projectData.customer || "");
  const [dateReceived, setDateReceived] = useState(
    projectData.dateReceived || ""
  );
  const [workType, setWorkType] = useState(
    projectData.workType || "commercial"
  );

  useEffect(() => {
    setProjectName(projectData.projectName || "");
    setContractor(projectData.contractor || "");
    setGeneralContractor(projectData.generalContractor || "");
    setCustomer(projectData.customer || "");
    setDateReceived(projectData.dateReceived || "");
    setWorkType(projectData.workType || "commercial");
  }, [projectData]);

  return (
    <div className="flex flex-col mx-1 space-y-8 ">
      <CustomInput
        value={projectName}
        onChange={handleInputChange(setProjectName, "projectName")}
        placeholder="Project Name"
        name="projectName"
        showClearIcon={true}
        className="w-full"
        variantType="standard"
      />
      <CustomInput
        value={contractor}
        onChange={handleInputChange(setContractor, "contractor")}
        placeholder="Contractor"
        name="contractor"
        showClearIcon={true}
        className="w-full"
        variantType="standard"
      />
      <CustomInput
        value={generalContractor}
        onChange={handleInputChange(setGeneralContractor, "generalContractor")}
        placeholder="General Contractor"
        name="generalContractor"
        showClearIcon={true}
        className="w-full"
        variantType="standard"
      />
      <CustomInput
        value={customer}
        onChange={handleInputChange(setCustomer, "customer")}
        placeholder="Customer"
        name="customer"
        showClearIcon={true}
        className="w-full"
        variantType="standard"
      />
      <CustomInput
        value={dateReceived}
        onChange={handleInputChange(setDateReceived, "dateReceived")}
        placeholder="Date Received"
        name="dateReceived"
        type="date"
        showClearIcon={true}
        className="w-full"
        variantType="standard"
      />
      <div className="flex justify-around items-center">
        {["commercial", "residential"].map((type) => (
          <label key={type} className="flex items-center">
            <input
              type="radio"
              name="workType"
              checked={workType === type}
              onChange={() => {
                setWorkType(type);
                onChange("workType", type);
              }}
              className="mr-2"
            />{" "}
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </label>
        ))}
      </div>
      <CustomButton
        className="mt-4 w-[80%]"
        variant="contained"
        color="primary"
        onClick={onSubmit}
      >
        Create Project
      </CustomButton>
    </div>
  );
};

export default ProjectDetailsForm;
