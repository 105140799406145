import React from "react";
import CustomButton from "../../components/CustomButton";

const PricingSection: React.FC = () => {
  const handlePlanClick = (plan: string) => {
    alert(`${plan} selected. More information will be available once testing is complete.`);
  };

  return (
    <div className="mt-10 px-2 py-4 bg-gray-100 mx-auto max-w-4xl rounded-lg shadow">
      <h2 className="text-2xl font-semibold text-left">Pricing</h2>
      <p className="mt-2 text-lg text-gray-600 text-left">
        Choose the plan that suits your business needs.
      </p>
      <div className="flex flex-col sm:flex-row sm:space-x-4 justify-around mt-6 text-left">
        <div className="bg-white p-3 rounded-lg shadow-lg mb-6 sm:mb-0 sm:w-full">
          <h3 className="text-lg font-semibold">Basic Plan</h3>
          <ul className="list-disc mt-4 mb-4 list-inside text-lg text-gray-700 space-y-2">
            <li className="hover:shadow-lg transform hover:scale-105 transition duration-300 ease-in-out">
              $65/month
            </li>
            <li className="hover:shadow-lg transform hover:scale-105 transition duration-300 ease-in-out">
              Up to 5GB storage
            </li>
            <li className="hover:shadow-lg transform hover:scale-105 transition duration-300 ease-in-out">
              100 jobs/month
            </li>
            <li className="hover:shadow-lg transform hover:scale-105 transition duration-300 ease-in-out">
              5 user accounts
            </li>
            <p className="text-[1rem] font-semibold">
              Perfect for individuals, self-employed professionals, or small
              companies.
            </p>
          </ul>
          <div className="flex justify-center mb-2">
            <CustomButton
              className="mt-4 w-[80%]"
              variant="contained"
              color="primary"
              onClick={() => handlePlanClick("Basic Plan")}
            >
              Buy Now
            </CustomButton>
          </div>
        </div>

        <div className="bg-white p-3 rounded-lg shadow-lg mb-6 sm:mb-0 sm:w-full">
          <h3 className="text-lg font-semibold">Pro Plan</h3>
          <ul className="list-disc mt-4 mb-4 list-inside text-lg text-gray-700 space-y-2">
            <li className="hover:shadow-lg transform hover:scale-105 transition duration-300 ease-in-out">
              $250/month
            </li>
            <li className="hover:shadow-lg transform hover:scale-105 transition duration-300 ease-in-out">
              50GB storage
            </li>
            <li className="hover:shadow-lg transform hover:scale-105 transition duration-300 ease-in-out">
              250 jobs/month
            </li>
            <li className="hover:shadow-lg transform hover:scale-105 transition duration-300 ease-in-out">
              Unlimited user accounts
            </li>
            <p className="text-[1rem] font-semibold">
              Ideal for small to medium-sized companies aiming for growth.
            </p>
          </ul>
          <div className="flex justify-center mb-2">
            <CustomButton
              className="mt-4 w-[80%]"
              variant="contained"
              color="secondary"
              onClick={() => handlePlanClick("Pro Plan")}
            >
              Buy Now
            </CustomButton>
          </div>
        </div>

        <div className="bg-white p-3 rounded-lg shadow-lg mb-6 sm:mb-0 sm:w-full">
          <h3 className="text-lg font-semibold">Enterprise Plan</h3>
          <ul className="list-disc mt-4 mb-4 list-inside text-lg text-gray-700 space-y-2">
            <li className="hover:shadow-lg transform hover:scale-105 transition duration-300 ease-in-out">
              Custom Pricing
            </li>
            <li className="hover:shadow-lg transform hover:scale-105 transition duration-300 ease-in-out">
              Tailored Solutions
            </li>
            <li className="hover:shadow-lg transform hover:scale-105 transition duration-300 ease-in-out">
              24/7 Customer Support
            </li>
            <li className="hover:shadow-lg transform hover:scale-105 transition duration-300 ease-in-out">
              Scalable Growth Plans
            </li>
            <p className="text-[1rem] font-semibold">
              Designed for large enterprises or smaller companies looking to
              expand rapidly. Contact us for more information.
            </p>
          </ul>
          <div className="flex justify-center mb-2">
            <CustomButton
              className="mt-4 w-[80%]"
              variant="contained"
              color="success"
              onClick={() => handlePlanClick("Enterprise Plan")}
            >
              Contact Us
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingSection;
