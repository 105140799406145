import React from 'react';
import { AiOutlineLine, AiOutlineAreaChart, AiOutlineDotChart } from 'react-icons/ai';
import { MdScatterPlot } from "react-icons/md";
import { TbChartDots3 } from "react-icons/tb";
import { TbChartAreaFilled } from "react-icons/tb";



const FloatingMenu: React.FC = () => {
  return (
    <div className="fixed bottom-0 h-fit min-h-[60px]  bg-gray-200 shadow-lg text-sm p-2 rounded-t-lg flex justify-around space-x-1 z-10 w-[100%]">
      <button className="bg-blue-500 min-w-fit m-3 w-[100px] text-white px-4 py-2 rounded flex items-center justify-center space-x-2">
        <TbChartDots3 /> 
        <span>Add Linear</span>
      </button>
      <button className="bg-blue-500 m-3  min-w-fit w-[100px] text-white px-4 py-2 rounded flex items-center justify-center space-x-2">
        <TbChartAreaFilled />
        <span>Add Sq.Ft</span>
      </button>
      <button className="bg-blue-500 m-3  min-w-fit w-[100px] text-white px-4 py-2 rounded flex items-center justify-center space-x-2">
        <MdScatterPlot />
        <span>Count</span>
      </button>
    </div>
  );
};

export default FloatingMenu;
