// utils/getDeviceType.ts
declare global {
  interface Navigator {
    standalone?: boolean;
  }
}

export function getDeviceType() {
  const ua = navigator.userAgent;
  if (/android/i.test(ua)) {
    return "android";
  }
  if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
    return "ios";
  }
  return "desktop";
}

export function isStandalone() {
  return (navigator.standalone || window.matchMedia('(display-mode: standalone)').matches);
}
