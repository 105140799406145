export const getBidLevel = (totalBidsCreated: number): string => {
  if (totalBidsCreated < 3) return "Baby Estimator";
  if (totalBidsCreated < 10) return "Jr. Estimator";
  if (totalBidsCreated < 25) return "Estimator";
  if (totalBidsCreated < 100) return "Ultra Estimator";
  return "Beyond Plus Ultra Estimator";
};

export const getLevelProgress = (totalBidsCreated: number): number => {
  if (totalBidsCreated < 3) return (totalBidsCreated / 3) * 100;
  if (totalBidsCreated < 10) return ((totalBidsCreated - 3) / (10 - 3)) * 100;
  if (totalBidsCreated < 25) return ((totalBidsCreated - 10) / (25 - 10)) * 100;
  if (totalBidsCreated < 100) return ((totalBidsCreated - 25) / (100 - 25)) * 100;
  return 100;
};

