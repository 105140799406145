import React from "react";
import { Pie, Bar, Line } from "react-chartjs-2";

const costData = {
  labels: ["Money spent on Trade Forge", "Money spent hiring an Estimator"],
  datasets: [
    {
      label: "Cost ($)",
      data: [3000, 70000], // Example data
      backgroundColor: ["rgba(34, 139, 34, 0.6)", "rgba(178, 34, 34, 0.6)"],
      borderColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
      borderWidth: 1,
    },
  ],
};

const accuracyData = {
  labels: ["Avg Estimator Output", "TradeForge Estimator Output"],
  datasets: [
    {
      label: "",
      data: [788, 2788], // Example data
      backgroundColor: ["rgba(178, 34, 34, 0.6)", "rgba(34, 139, 34, 0.6)"],
      borderColor: ["rgba(255, 99, 132, 1)", "rgba(75, 192, 192, 1)"],
      borderWidth: 1,
    },
  ],
};

const timeData = {
  labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
  datasets: [
    {
      label: "Time to Bid (hours)",
      data: [10, 8, 5, 3], // Example data
      fill: false,
      borderColor: "rgba(75, 192, 192, 1)",
      tension: 0.1,
    },
  ],
};

const pieOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top" as const,
    },
    tooltip: {
      enabled: true,
    },
  },
  animation: {
    animateScale: true,
    animateRotate: true,
  },
};

const barOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
    },
  },
};

const lineOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top" as const,
    },
    tooltip: {
      enabled: true,
    },
  },
};

const ChartsSection: React.FC = () => {
  return (
    <>
      <div className="mt-10">
        <h2 className="text-[1.05rem] sm:text-[1.5rem] sm:mb-6 font-semibold">
          Trade Forge Saves You Money And Time{" "}
        </h2>
        <div
          className="mx-auto relative"
          style={{ width: "100%", maxWidth: "300px", height: "300px" }}
        >
          <Pie data={costData} options={pieOptions} />
        </div>
        <p className="mt-4 text-lg sm:max-w-[50%] ml-auto mr-auto text-gray-600">
          Experience a significant boost in productivity with Trade Forge. Our
          tools ensure that you spend less time managing bids and more time
          completing profitable projects.
        </p>
      </div>

      <div className="mt-10">
        <h2 className="text-[1.4rem] sm:text-[1.5rem] mb-3 sm:mb-6 font-semibold">
          Improved Estimator Output{" "}
        </h2>
        <div
          className="mx-auto relative"
          style={{ width: "100%", maxWidth: "600px", height: "400px" }}
        >
          <Bar data={accuracyData} options={barOptions} />
        </div>
      </div>

      <div className="mt-10">
        <h2 className="text-[1.4rem] sm:text-[1.5rem] mb-3 sm:mb-6 font-semibold">
          Reduced Bidding Time
        </h2>
        <div
          className="mx-auto relative"
          style={{ width: "100%", maxWidth: "600px", height: "400px" }}
        >
          <Line data={timeData} options={lineOptions} />
        </div>
      </div>
    </>
  );
};

export default ChartsSection;
