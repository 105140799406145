import React from 'react';
import Modal from '../../components/Modal';
import CustomInput from '../../components/CustomInput';
import CustomButton from '../../components/CustomButton';

interface UploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  fileName: string;
  setFileName: (value: string) => void;
  onConfirm: () => void;
}

const UploadModal: React.FC<UploadModalProps> = ({ isOpen, onClose, fileName, setFileName, onConfirm }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <CustomInput
      value={fileName}
      onChange={(e) => setFileName(e.target.value)}
      placeholder="Photo Name"
      className="w-full"
    />
    <div className="flex justify-end mt-4">
      <CustomButton variant="contained" color="primary" onClick={onConfirm}>
        Upload
      </CustomButton>
    </div>
  </Modal>
);

export default UploadModal;
