import React, { useState } from 'react';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import CircularProgressBar from '../../CircularProgressBar/CircularProgressBar';
import ProjectList from '../ProjectList/ProjectList';
import { Project } from '../../../types/Project';

interface ProjectDropdownProps {
  title: string;
  projects: Project[];
  onProjectClick: (projectId: string) => void;
  progressPercentage: number;
}

const ProjectDropdown: React.FC<ProjectDropdownProps> = ({ title, projects, onProjectClick, progressPercentage }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="mt-4 bg-gray-100 p-4 rounded-lg cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
        <div className="flex flex-col">
          <div className="flex justify-between">
            <div>
              <h3 className="font-semibold flex items-center">
                {title}
                {isOpen ? <AiOutlineUp className="ml-2" /> : <AiOutlineDown className="ml-2" />}
              </h3>
              <p className="text-sm text-gray-500">Click to expand and see all jobs</p>
            </div>
            <div style={{ width: 50, height: 50 }}>
              <CircularProgressBar percentage={progressPercentage} />
            </div>
          </div>
        </div>
        <p className="text-sm">
          <strong>total active projects:</strong> {projects.length}
        </p>
      </div>

      {isOpen && (
        <div className="relative ml-4 mt-2">
          <div className="absolute top-0 bottom-0 ml-4 -mt-2 left-[-1rem] w-1 bg-blue-500"></div>
          <ProjectList projects={projects} onProjectClick={onProjectClick} />
        </div>
      )}
    </>
  );
};

export default ProjectDropdown;
