import React from "react";

const MissionSection: React.FC = () => {
  return (
    <div className="mt-8 px-6 py-4 bg-gray-100 mx-auto max-w-4xl text-center rounded-lg shadow">
      <h2 className="text-xl font-semibold">Our Mission</h2>
      <p className="mt-2">
        We aim to revolutionize the painting industry by providing robust,
        scalable, and intuitive tools that facilitate better
        decision-making, increased operational efficiency, and enhanced
        pricing accuracy.
      </p>
    </div>
  );
};

export default MissionSection;
