// OK BUT WHEN I NAV HERE FROM PROJECT BUTTONS IT SHOULD JUST BASICALLY NAV HERE AND CLICK PLUS BUTTON SO THAT IT STILL PULLS CURRENT MATERIALS AND THEN STARTS ADDING FROM CURRENT P-N

import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import ProjectNavigation from "../../../components/ProjectNavigation";
import swatchData from "../../../assets/paint_colors.json";
import paintProducts from "../../../assets/paint_products.json";
import CustomInput from "../../../components/CustomInput";
import { MdDelete } from "react-icons/md";
import { db } from "../../../services/firebase-config";
import {
  collection,
  addDoc,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { Swatch } from "../../../types/Project";
import SwatchTile from "../../../components/SwatchTile/SwatchTile";
import { scrollToTop } from "../../../utils/scrollUtil";
import Modal from "../../../components/Modal";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { IoIosArrowBack } from "react-icons/io";

interface PaintProduct {
  productId: string;
  name: string;
  maxCoverage: number;
  minCoverage: number;
  VOC: number;
  costPerGallon: number;
}

const FinishPage: React.FC = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [swatches, setSwatches] = useState<Swatch[]>([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [hexInput, setHexInput] = useState("#");
  const [nameInput, setNameInput] = useState("");
  const [swCodeInput, setSwCodeInput] = useState("");
  const [productTypeInput, setProductTypeInput] = useState("");
  const [paintCodeInput, setPaintCodeInput] = useState("P-1");
  const [productSuggestions, setProductSuggestions] = useState<PaintProduct[]>(
    []
  );
  const [hexSuggestions, setHexSuggestions] = useState<Swatch[]>([]);
  const [nameSuggestions, setNameSuggestions] = useState<Swatch[]>([]);
  const [swCodeSuggestions, setSwCodeSuggestions] = useState<Swatch[]>([]);
  const productTypeInputRef = useRef<HTMLInputElement>(null);
  const hexInputRef = useRef<HTMLInputElement>(null);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const swCodeInputRef = useRef<HTMLInputElement>(null);
  const [selectedProduct, setSelectedProduct] = useState<PaintProduct | null>(
    null
  );
  const [selectedSwatch, setSelectedSwatch] = useState<Swatch | null>(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const isAddMaterial = searchParams.get("addMaterial") === "true";
  
  

  
  const handleAddSwatchClick = () => {
    setIsAdding(true);
    setIsEditing(false);
    clearInputs();
    setPaintCodeInput(getNextPaintCode()); // Set the next available paint code
    document.body.classList.add("modal-open");
  };

  const handleEditSwatchClick = (index: number) => {
    const swatch = swatches[index];
    setIsEditing(true);
    setEditingIndex(index);
    setHexInput(swatch.hex);
    setNameInput(swatch.name);
    setSwCodeInput(swatch.swCode);
    setProductTypeInput(swatch.productType || "");
    setPaintCodeInput(swatch.paintCode || "");
    setSelectedProduct(null);
    setIsAdding(true);
    document.body.classList.add("modal-open");
  };

  const handleDeleteSwatchClick = (index: number) => {
    setEditingIndex(index);
    setConfirmDeleteOpen(true);
    document.body.classList.add("modal-open");
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const value = event.target.value;
    // console.log(`Input change detected. Type: ${type}, Value: ${value}`);

    if (type === "hex") {
      setHexInput(value);
      const filteredSuggestions = swatchData.filter((swatch) =>
        swatch.hex.toLowerCase().includes(value.toLowerCase())
      ) as Swatch[];
      setHexSuggestions(filteredSuggestions);
      setNameSuggestions([]);
      setSwCodeSuggestions([]);
    } else if (type === "name") {
      setNameInput(value);
      const filteredSuggestions = swatchData.filter((swatch) =>
        swatch.name.toLowerCase().includes(value.toLowerCase())
      ) as Swatch[];
      setNameSuggestions(filteredSuggestions);
      setHexSuggestions([]);
      setSwCodeSuggestions([]);
    } else if (type === "swCode") {
      setSwCodeInput(value);
      const filteredSuggestions = swatchData.filter((swatch) =>
        swatch.swCode.toLowerCase().includes(value.toLowerCase())
      ) as Swatch[];
      setSwCodeSuggestions(filteredSuggestions);
      setHexSuggestions([]);
      setNameSuggestions([]);
    } else if (type === "productType") {
      setProductTypeInput(value);
      const filteredSuggestions = paintProducts.filter((product) =>
        product.name.toLowerCase().includes(value.toLowerCase())
      );
      setProductSuggestions(filteredSuggestions);
    }
  };

  const handleSwatchSelect = async () => {
    const newSwatch: Omit<Swatch, "id"> = {
      name: nameInput,
      hex: hexInput,
      swCode: swCodeInput,
      productType: selectedProduct ? selectedProduct.name : productTypeInput,
      paintCode: paintCodeInput,
    };

    if (isEditing && editingIndex !== null) {
      const swatchId = swatches[editingIndex].id;
      if (swatchId) {
        const swatchDocRef = doc(
          db,
          "projects",
          projectId!,
          "swatches",
          swatchId
        );
        await updateDoc(swatchDocRef, newSwatch);
        const updatedSwatches = [...swatches];
        updatedSwatches[editingIndex] = { ...newSwatch, id: swatchId };
        updatedSwatches.sort(
          (a, b) =>
            parseInt(a.paintCode.split("-")[1]) -
            parseInt(b.paintCode.split("-")[1])
        );
        setSwatches(updatedSwatches);
        // console.log("Swatch updated: ", newSwatch);
      }
    } else {
      const swatchDocRef = await addDoc(
        collection(db, "projects", projectId!, "swatches"),
        newSwatch
      );
      const newSwatchWithId: Swatch = { ...newSwatch, id: swatchDocRef.id };
      const updatedSwatches = [...swatches, newSwatchWithId];
      updatedSwatches.sort(
        (a, b) =>
          parseInt(a.paintCode.split("-")[1]) -
          parseInt(b.paintCode.split("-")[1])
      );
      setSwatches(updatedSwatches);
      // console.log("New swatch added: ", newSwatchWithId);
    }
    scrollToTop();

    setIsAdding(false);
    clearInputs();
    setProductSuggestions([]);
    setHexSuggestions([]);
    setNameSuggestions([]);
    setSwCodeSuggestions([]);
    setSelectedProduct(null);
    setSelectedSwatch(null);
    document.body.classList.remove("modal-open");
  };

  const handleDeleteSwatch = async () => {
    if (editingIndex !== null) {
      const swatchId = swatches[editingIndex].id;
      if (swatchId) {
        const swatchDocRef = doc(
          db,
          "projects",
          projectId!,
          "swatches",
          swatchId
        );
        await deleteDoc(swatchDocRef);
        const updatedSwatches = swatches.filter(
          (_, idx) => idx !== editingIndex
        );
        updatedSwatches.sort(
          (a, b) =>
            parseInt(a.paintCode.split("-")[1]) -
            parseInt(b.paintCode.split("-")[1])
        );
        setSwatches(updatedSwatches);
        setEditingIndex(null);
        setConfirmDeleteOpen(false);
        setIsAdding(false);
        // console.log("Swatch deleted: ", swatchId);
        document.body.classList.remove("modal-open");
      }
    }
  };

  const handleProductSelect = (product: PaintProduct) => {
    // console.log("Product selected: ", product);
    setProductTypeInput(product.name);
    setSelectedProduct(product);
    setProductSuggestions([]);
  };

  const handleSwatchSuggestionSelect = (swatch: Swatch) => {
    // console.log("Swatch selected: ", swatch);
    setHexInput(swatch.hex);
    setNameInput(swatch.name);
    setSwCodeInput(swatch.swCode);
    setSelectedSwatch(swatch);
    setHexSuggestions([]);
    setNameSuggestions([]);
    setSwCodeSuggestions([]);
  };

  const handleCloseModal = () => {
    setIsAdding(false);
    clearInputs();
    setProductSuggestions([]);
    setHexSuggestions([]);
    setNameSuggestions([]);
    setSwCodeSuggestions([]);
    setSelectedProduct(null);
    setSelectedSwatch(null);
    document.body.classList.remove("modal-open");
  };

  const clearInputs = () => {
    setHexInput("#");
    setNameInput("");
    setSwCodeInput("");
    setProductTypeInput("");
    setPaintCodeInput(getNextPaintCode());
  };

  const highlightText = (text: string, highlight: string) => {
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} className="bg-yellow-200">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const getNextPaintCode = () => {
    const codes = swatches.map((swatch) =>
      parseInt(swatch.paintCode.split("-")[1])
    );
    for (let i = 1; i <= swatches.length + 1; i++) {
      if (!codes.includes(i)) {
        return `P-${i}`;
      }
    }
    return `P-${swatches.length + 1}`;
  };
  
  useEffect(() => {
    const fetchSwatches = async () => {
      const querySnapshot = await getDocs(
        collection(db, "projects", projectId!, "swatches")
      );
      const swatchesData: Swatch[] = querySnapshot.docs.map((doc) => {
        const data = doc.data() as Swatch;
        return {
          id: doc.id,
          name: data.name,
          hex: data.hex,
          swCode: data.swCode,
          productType: data.productType,
          paintCode: data.paintCode,
        };
      });
      swatchesData.sort(
        (a, b) =>
          parseInt(a.paintCode.split("-")[1]) -
          parseInt(b.paintCode.split("-")[1])
      );
      setSwatches(swatchesData);
    };

    if (projectId) {
      fetchSwatches();
    }
  }, [projectId]);

  return (
    <div className="relative min-h-screen h-[200rem]">
      <div className="sticky top-0 left-0 right-0 bg-white z-50">
        <div className="flex justify-between p-4">
          <IoIosArrowBack
            onClick={() => navigate(-1)}
            className="text-2xl cursor-pointer"
          />
          <p
            className="text-blue-500 text-xl cursor-pointer"
            onClick={() => navigate(`/project-home/${projectId}`)}
          >
            Back To Project
          </p>
        </div>
        <h1 className="text-3xl text-start ml-[2.5rem]">Materials</h1>
        <ProjectNavigation />
      </div>

      <div className="pt-[1rem]">
        <p className="text-xl ml-4 font-semibold">Finishes</p>
        <div className="flex items-center overflow-x-auto w-full py-2 mb-12 bg-white">
          <div className="flex min-h-[4rem] space-x-4 mx-1 relative">
            <div
              className="w-32 h-36 border rounded-lg flex-shrink-0 flex items-center justify-center cursor-pointer"
              onClick={handleAddSwatchClick}
            >
              <AiOutlinePlus className="text-4xl text-gray-400" />
            </div>
            <div className="flex space-x-1 z-10">
              {swatches.length === 0 ? (
                <div className="text-gray-500 ml-4">
                  No finishes added. Please add a finish.
                </div>
              ) : (
                swatches.map((swatch, index) => (
                  <SwatchTile
                    key={index}
                    swatch={swatch}
                    onClick={() => handleEditSwatchClick(index)}
                  />
                ))
              )}
            </div>
          </div>
        </div>
        <div className="flex-grow"></div>
        <div className="mx-7">
          <p className="text-[1.1rem] font-thin">
            Add Blueprint docs or Estimate Items to assign paint types
          </p>
        </div>
        <div className="border-t mt-[2rem]">
          <p className="text-lg ml-4 mt-[.9rem] font-semibold">Materials</p>
        </div>
      </div>

      <Modal isOpen={isAdding} onClose={handleCloseModal} className="h-[80%]">
        <div className="flex content-center items-center justify-between">
          <p className="ml-4 font-semibold text-xl">
            {isEditing ? "Edit Finish" : "Add a Finish"}
          </p>
          {isEditing && (
            <IconButton
              onClick={() => handleDeleteSwatchClick(editingIndex!)}
              className="absolute -top-2 right-8"
            >
              <MdDelete />
            </IconButton>
          )}
        </div>
        <div className="border-t border-gray-300 mt-3 mb-6"></div>
        <div className="mt-12 ml-4 mx-2 space-y-3">
          <CustomInput
            value={paintCodeInput}
            onChange={(e) => setPaintCodeInput(e.target.value)}
            placeholder="Paint code"
            className="w-full mb-2"
          />
          <div className="relative">
            <CustomInput
              value={productTypeInput}
              onChange={(e) => handleInputChange(e, "productType")}
              placeholder="Product Type"
              className="w-full mb-2"
              ref={productTypeInputRef}
            />
            {productSuggestions.length > 0 && (
              <div
                className="absolute bg-white border rounded-lg shadow-lg z-50"
                style={{
                  top: (productTypeInputRef.current?.offsetHeight || 0) + 8,
                  left: 0,
                  width: productTypeInputRef.current?.offsetWidth || "100%",
                }}
              >
                {productSuggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    className={`p-2 cursor-pointer hover:bg-gray-200 ${
                      index % 2 === 0 ? "bg-gray-100" : ""
                    }`}
                    onMouseDown={() => {
                      handleProductSelect(suggestion);
                      setProductTypeInput(suggestion.name);
                      // console.log("Product type input set to: ", suggestion.name);
                    }}
                  >
                    <span>
                      {highlightText(suggestion.name, productTypeInput)}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="relative">
            <CustomInput
              value={nameInput}
              onChange={(e) => handleInputChange(e, "name")}
              placeholder="Color Name"
              className="w-full mb-2"
              ref={nameInputRef}
            />
            {nameSuggestions.length > 0 && (
              <div
                className="absolute bg-white border rounded-lg shadow-lg z-50"
                style={{
                  top: (nameInputRef.current?.offsetHeight || 0) + 8,
                  left: 0,
                  width: nameInputRef.current?.offsetWidth || "100%",
                }}
              >
                {nameSuggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    className={`p-2 cursor-pointer hover:bg-gray-200 ${
                      index % 2 === 0 ? "bg-gray-100" : ""
                    }`}
                    onMouseDown={() => {
                      handleSwatchSuggestionSelect(suggestion);
                      // console.log("Swatch selected: ", suggestion);
                    }}
                  >
                    <span>{highlightText(suggestion.name, nameInput)}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="relative">
            <CustomInput
              value={swCodeInput}
              onChange={(e) => handleInputChange(e, "swCode")}
              placeholder="SW Code"
              className="w-full mb-2"
              ref={swCodeInputRef}
            />
            {swCodeSuggestions.length > 0 && (
              <div
                className="absolute bg-white border rounded-lg shadow-lg z-50"
                style={{
                  top: (swCodeInputRef.current?.offsetHeight || 0) + 8,
                  left: 0,
                  width: swCodeInputRef.current?.offsetWidth || "100%",
                }}
              >
                {swCodeSuggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    className={`p-2 cursor-pointer hover:bg-gray-200 ${
                      index % 2 === 0 ? "bg-gray-100" : ""
                    }`}
                    onMouseDown={() => {
                      handleSwatchSuggestionSelect(suggestion);
                      // console.log("Swatch selected: ", suggestion);
                    }}
                  >
                    <span>{highlightText(suggestion.swCode, swCodeInput)}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="relative">
            <CustomInput
              value={hexInput}
              onChange={(e) => handleInputChange(e, "hex")}
              placeholder="Hex Code"
              className="w-full mb-2"
              ref={hexInputRef}
            />
            {hexSuggestions.length > 0 && (
              <div
                className="absolute bg-white border rounded-lg shadow-lg z-50"
                style={{
                  top: (hexInputRef.current?.offsetHeight || 0) + 8,
                  left: 0,
                  width: hexInputRef.current?.offsetWidth || "100%",
                }}
              >
                {hexSuggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    className={`p-2 cursor-pointer hover:bg-gray-200 ${
                      index % 2 === 0 ? "bg-gray-100" : ""
                    }`}
                    onMouseDown={() => {
                      handleSwatchSuggestionSelect(suggestion);
                      // console.log("Swatch selected: ", suggestion);
                    }}
                  >
                    <span>{highlightText(suggestion.hex, hexInput)}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
          <button
            onClick={handleSwatchSelect}
            className="mt-4 bg-blue-500 text-white p-2 rounded-lg"
          >
            {isEditing ? "Update Finish" : "Add Finish"}
          </button>
        </div>
      </Modal>
      <Dialog
        open={confirmDeleteOpen}
        onClose={() => {
          setConfirmDeleteOpen(false);
          document.body.classList.remove("modal-open");
        }}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this swatch?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setConfirmDeleteOpen(false);
              document.body.classList.remove("modal-open");
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleDeleteSwatch} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FinishPage;
