import React from "react";

const VerificationMessage: React.FC = () => (
  <p className="mt-8 mb-8">
    A verification email has been sent to your email address. Please verify your email to gain full access. Once verified, click{" "}
    <a href="/signin" className="text-blue-500 hover:text-blue-700">
      here
    </a>{" "}
    to sign in.
  </p>
);

export default VerificationMessage;
