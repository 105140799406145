import React from "react";
import { Link } from "react-router-dom";

interface NavLinksProps {
  currentUser: any;
  showGetAppButton: boolean;
  onClick?: () => void;
}

const NavLinks: React.FC<NavLinksProps> = ({ currentUser, showGetAppButton, onClick }) => {
  return (
    <>
      {currentUser ? (
        <>
          <li className="mb-4 list-none  lg:pl-5">
            <Link to="/" className="text-xl hover:text-gray-300" onClick={onClick}>
              Home
            </Link>
          </li>
          <li className="mb-4 list-none  lg:pl-5">
            <Link to="/account" className="text-xl hover:text-gray-300" onClick={onClick}>
              Account
            </Link>
          </li>
          <li className="mb-4 list-none  lg:pl-5">
            <Link to="/about-us" className="text-xl hover:text-gray-300" onClick={onClick}>
              About Us
            </Link>
          </li>
        </>
      ) : (
        <>
          <li className="mb-4 list-none lg:list-disc lg:pl-5">
            <Link to="/about-us" className="text-xl hover:text-gray-300" onClick={onClick}>
              About Us
            </Link>
          </li>
          <li className="mb-4 list-none lg:list-disc lg:pl-5">
            <Link to="/signin" className="text-xl hover:text-gray-300" onClick={onClick}>
              Log In
            </Link>
          </li>
          <li className="mb-4 list-none lg:list-disc lg:pl-5">
            <Link to="/register" className="text-xl hover:text-gray-300" onClick={onClick}>
              Register
            </Link>
          </li>
          {showGetAppButton && (
            <li className="mb-4 list-none lg:list-disc lg:pl-5">
              <Link to="/install-app" className="text-xl hover:text-gray-300" onClick={onClick}>
                Get the App
              </Link>
            </li>
          )}
        </>
      )}
    </>
  );
};

export default NavLinks;
