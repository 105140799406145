import React, {
  useState,
  useEffect,
  useRef,
  ChangeEvent,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AiOutlinePlus,
  AiOutlineEllipsis,
  AiOutlineDown,
  AiOutlineUp,
} from "react-icons/ai";
import CustomButton from "../../../components/CustomButton";
import ProjectNavigation from "../../../components/ProjectNavigation";
import { db } from "../../../services/firebase-config";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { EstimateType, LineItem } from "../../../types/Project";
import Modal from "../../../components/Modal";
import CustomInput from "../../../components/CustomInput";
import paintingItems from "../../../assets/interior_painting_items.json";
import SnackbarMessage from "../../../components/SnackBarMessage";
import { Menu, MenuItem } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { IoIosArrowBack } from "react-icons/io";


const CreateEstimatePage: React.FC = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [estimates, setEstimates] = useState<EstimateType[]>([]);
  const [documentsCount, setDocumentsCount] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeEstimate, setActiveEstimate] = useState<EstimateType | null>(
    null
  );
  const [newLineItems, setNewLineItems] = useState<Partial<LineItem>[]>([
    { description: "", quantity: 1, unitCost: 0 },
  ]);
  const [lineItems, setLineItems] = useState<LineItem[]>([]);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [menuAnchorEls, setMenuAnchorEls] = useState<(HTMLElement | null)[]>(
    new Array(lineItems.length).fill(null)
  );
  const [loading, setLoading] = useState(true); // Add loading state

  const [isCollapsed, setIsCollapsed] = useState(false);

  const descriptionInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    async function fetchEstimatesAndDocuments() {
      if (!projectId) return;
  
      // Fetch estimates and documents
      const estimatesSnapshot = await getDocs(collection(db, "projects", projectId, "estimates"));
      const estimatesData: EstimateType[] = estimatesSnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      } as EstimateType));
      
      setEstimates(estimatesData);
      setActiveEstimate(estimatesData[0]);
      setLineItems(estimatesData[0]?.items || []);
      setMenuAnchorEls(new Array(estimatesData[0]?.items.length || 0).fill(null));
  
      const documentsSnapshot = await getDocs(collection(db, "projects", projectId, "documents"));
      setDocumentsCount(documentsSnapshot.size);
  
      setLoading(false);  // Set loading to false after data is fetched
    }
  
    fetchEstimatesAndDocuments();
  }, [projectId]);
  

  const handleUploadBlueprints = () => {
    navigate(`/blueprints/${projectId}`);
  };

  const handleAddLineItem = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    if (
      newLineItems.some(
        (item) => item.description || item.quantity || item.unitCost
      )
    ) {
      if (
        window.confirm("You have unsaved changes. Do you want to save them?")
      ) {
        handleAddLineItemToEstimate();
      } else {
        setIsModalOpen(false);
        setNewLineItems([{ description: "", quantity: 1, unitCost: 0 }]);
        setSuggestions([]);
      }
    } else {
      setIsModalOpen(false);
      setNewLineItems([{ description: "", quantity: 1, unitCost: 0 }]);
      setSuggestions([]);
    }
  };

  const handleAddLineItemToEstimate = async () => {
    if (!activeEstimate || !projectId) return;

    const lineItemsToAdd = newLineItems.map((item) => ({
      id: `line_item_${Date.now()}`,
      description: item.description!,
      quantity: item.quantity!,
      unitCost: item.unitCost!,
      totalCost: item.quantity! * item.unitCost!,
    }));

    const updatedLineItems = [...lineItems, ...lineItemsToAdd];
    setLineItems(updatedLineItems);

    const updatedEstimate = {
      ...activeEstimate,
      items: updatedLineItems,
    };

    if (projectId && activeEstimate.id) {
      await updateDoc(
        doc(db, "projects", projectId, "estimates", activeEstimate.id),
        updatedEstimate
      );
    }

    setIsModalOpen(false);
    setNewLineItems([{ description: "", quantity: 1, unitCost: 0 }]);
    setSuggestions([]);
    setMenuAnchorEls(new Array(updatedLineItems.length).fill(null));
  };

  const handleDescriptionChange = (index: number, value: string) => {
    const updatedLineItems = [...newLineItems];
    updatedLineItems[index].description = value;
    setNewLineItems(updatedLineItems);
    setSuggestions(
      value
        ? paintingItems.filter((item) =>
            item.toLowerCase().includes(value.toLowerCase())
          )
        : []
    );
  };

  const handleSuggestionClick = (index: number, suggestion: string) => {
    const updatedLineItems = [...newLineItems];
    updatedLineItems[index].description = suggestion;
    setNewLineItems(updatedLineItems);
    setSuggestions([]);
  };

  const handleQuantityChange = (index: number, value: number) => {
    const updatedLineItems = [...newLineItems];
    updatedLineItems[index].quantity = value;
    setNewLineItems(updatedLineItems);
  };

  const handleUnitCostChange = (index: number, value: number) => {
    const updatedLineItems = [...newLineItems];
    updatedLineItems[index].unitCost = value;
    setNewLineItems(updatedLineItems);
  };

  const handleAddNewItem = () => {
    setNewLineItems([
      ...newLineItems,
      { description: "", quantity: 1, unitCost: 0 },
    ]);
  };

  const quickAddItem = (item: string) => {
    const updatedLineItems = [...newLineItems];
    const lastItemIndex = updatedLineItems.length - 1;
    updatedLineItems[lastItemIndex].description = item;
    setNewLineItems(updatedLineItems);
    setSuggestions([]);
  };

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    const updatedMenuAnchorEls = [...menuAnchorEls];
    updatedMenuAnchorEls[index] = event.currentTarget;
    setMenuAnchorEls(updatedMenuAnchorEls);
  };

  const handleMenuClose = (index: number) => {
    const updatedMenuAnchorEls = [...menuAnchorEls];
    updatedMenuAnchorEls[index] = null;
    setMenuAnchorEls(updatedMenuAnchorEls);
  };

  const handleDeleteLineItem = (indexToDelete: number) => {
    if (activeEstimate) {
      const updatedLineItems = lineItems.filter(
        (_, idx) => idx !== indexToDelete
      );
      setLineItems(updatedLineItems);

      const updatedEstimate = {
        ...activeEstimate,
        items: updatedLineItems,
      };

      if (projectId && activeEstimate.id) {
        updateDoc(
          doc(db, "projects", projectId, "estimates", activeEstimate.id),
          updatedEstimate
        );
      }
      const updatedMenuAnchorEls = [...menuAnchorEls];
      updatedMenuAnchorEls.splice(indexToDelete, 1);
      setMenuAnchorEls(updatedMenuAnchorEls);
    }
  };

  const handleDeleteNewLineItem = (index: number) => {
    const updatedLineItems = newLineItems.filter((_, idx) => idx !== index);
    setNewLineItems(updatedLineItems);
    handleMenuClose(index);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const getBidTotal = () => {
    return lineItems.reduce((total, item) => total + (item.totalCost || 0), 0);
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };


  // Show loader if still fetching data
  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <CircularProgress />
      </div>
    );
  }

  
  return (
    <div className="relative min-h-screen">
      <div className="sticky top-0 left-0 right-0 bg-white z-50">
        <div className="flex justify-between p-4">
          <IoIosArrowBack
            onClick={() => navigate(-1)}
            className="text-2xl cursor-pointer"
          />
          <p
            className="text-blue-500 cursor-pointer text-xl"
            onClick={() => navigate(`/project-home/${projectId}`)}
          >
            Back To Project
          </p>
        </div>
        <h1 className="text-3xl ml-10">Estimate</h1>
        <ProjectNavigation />
      </div>

      <div className="px-4 mt-8">
        {documentsCount === 0 && (
          <div className="mt-[2rem] mb-6 border-dotted border-2 border-gray-400 rounded-md p-4 bg-gray-100 text-center">
            <span className="text-xl">Add blueprints to start estimate</span>
            <div className="mt-2">
              <CustomButton
                variant="contained"
                color="primary"
                onClick={handleUploadBlueprints}
              >
                Upload Blueprints
              </CustomButton>
            </div>
          </div>
        )}

        <div className="flex justify-center mt-4 mb-6">
          <div className="w-full max-w-xl p-2 bg-gray-400 text-white rounded-md flex items-center">
            <input type="checkbox" className="mr-2" />
            <span className="text-2xl">Items</span>
            <button className="ml-auto" onClick={toggleCollapse}>
              {isCollapsed ? <AiOutlineDown /> : <AiOutlineUp />}
            </button>
          </div>
        </div>
        {!isCollapsed && lineItems.length > 0 && (
          <div className="my-6">
            <ul>
              {lineItems.map((item, index) => (
                <li
                  key={item.id}
                  className="mb-2 p-2 border rounded-md text-lg flex justify-between items-center"
                >
                  <span className="w-1/3 break-words">{item.description}</span>
                  <span className="w-1/4 text-center">{item.quantity}</span>
                  <span className="w-1/4 text-center">${item.unitCost}</span>
                  <span className="w-1/4 text-center">${item.totalCost}</span>
                  <button
                    className="w-1/12 text-gray-500"
                    onClick={(event) => handleMenuClick(event, index)}
                  >
                    <AiOutlineEllipsis className="text-xl" />
                  </button>
                  <Menu
                    anchorEl={menuAnchorEls[index]}
                    open={Boolean(menuAnchorEls[index])}
                    onClose={() => handleMenuClose(index)}
                  >
                    <MenuItem
                      onClick={() => {
                        setSnackbarOpen(true);
                        setSnackbarMessage("Add Note: Coming Soon!");
                        handleMenuClose(index);
                      }}
                    >
                      Add Note
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setSnackbarOpen(true);
                        setSnackbarMessage("Add RFI: Coming Soon!");
                        handleMenuClose(index);
                      }}
                    >
                      Add RFI
                    </MenuItem>
                    <MenuItem onClick={() => handleDeleteLineItem(index)}>
                      Delete
                    </MenuItem>
                  </Menu>
                </li>
              ))}
            </ul>

            <div className="text-right  text-2xl my-6">
              Bid Total: ${getBidTotal().toFixed(2)}
            </div>
          </div>
        )}
        <div className="flex justify-center mt-4 border-gray-500 border-2 border-dotted rounded-md">
          <CustomButton
            variant="text"
            color="primary"
            fontSize="18px"
            className="w-full"
            onClick={handleAddLineItem}
          >
            Add Page or Line Items
          </CustomButton>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        className="bottom-modal"
      >
        <div className="flex flex-col space-y-4 mt-3 items-center justify-center w-full">
          {newLineItems.map((item, index) => (
            <div key={index} className="flex space-x-4 w-full items-center">
              <div className="relative w-1/3">
                <CustomInput
                  value={item.description || ""}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleDescriptionChange(index, e.target.value)
                  }
                  placeholder="Item Name"
                  className="w-full"
                  ref={descriptionInputRef}
                  onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
                    e.target.select()
                  }
                />
                {suggestions.length > 0 && (
                  <div className="absolute bg-white border rounded-lg shadow-lg z-50 w-full">
                    {suggestions.map((suggestion, idx) => (
                      <div
                        key={idx}
                        className="p-2 cursor-pointer hover:bg-gray-200"
                        onMouseDown={() =>
                          handleSuggestionClick(index, suggestion)
                        }
                      >
                        {suggestion}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <CustomInput
                value={item.quantity?.toString() || "1"}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleQuantityChange(index, Number(e.target.value))
                }
                placeholder="Quantity"
                className="w-1/4 text-center"
                type="number"
                onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
                  e.target.select()
                }
              />
              <div className="w-1/4 text-center flex items-center">
                <span className="mr-1">$</span>
                <CustomInput
                  value={item.unitCost?.toString() || ""}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleUnitCostChange(
                      index,
                      Number(e.target.value.replace("$", ""))
                    )
                  }
                  placeholder="Unit Cost"
                  className="w-full"
                  type="number"
                  onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
                    e.target.select()
                  }
                />
              </div>
              <button
                className="w-1/12 text-gray-500"
                onClick={(event) => handleMenuClick(event, index)}
              >
                <AiOutlineEllipsis className="text-xl" />
              </button>
              <Menu
                anchorEl={menuAnchorEls[index]}
                open={Boolean(menuAnchorEls[index])}
                onClose={() => handleMenuClose(index)}
              >
                <MenuItem onClick={() => handleDeleteNewLineItem(index)}>
                  Delete
                </MenuItem>
              </Menu>
            </div>
          ))}
          <div className="flex justify-center space-x-4 mb-4">
            <button
              className="outline-none border rounded-full px-4 py-1"
              onClick={() => quickAddItem("Trim")}
            >
              Trim
            </button>
            <button
              className="outline-none border rounded-full px-4 py-1"
              onClick={() => quickAddItem("Walls")}
            >
              Walls
            </button>
            <button
              className="outline-none border rounded-full px-4 py-1"
              onClick={() => quickAddItem("Ceilings")}
            >
              Ceilings
            </button>
            <button
              className="outline-none border rounded-full px-4 py-1"
              onClick={handleAddNewItem}
            >
              <AiOutlinePlus className="text-xl" />
            </button>
          </div>
          <div className="flex justify-center mb-4">
            <CustomButton
              variant="contained"
              color="primary"
              onClick={handleAddLineItemToEstimate}
            >
              Update Estimate
            </CustomButton>
          </div>
        </div>
      </Modal>
      <SnackbarMessage
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </div>
  );
};

export default CreateEstimatePage;
