import React, { useState } from "react";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";

interface SignInFormProps {
  loginUser: (formData: { email: string; password: string }) => Promise<void>;
  navigate: (path: string) => void;
  setMessage: (message: string) => void;
}

const SignInForm: React.FC<SignInFormProps> = ({
  loginUser,
  navigate,
  setMessage,
}) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      await loginUser(formData);
      navigate("/");
    } catch (error) {
      console.error("Failed to sign in:", error);
      setMessage(
        (error as { message?: string }).message || "Failed to sign in."
      );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-6">
        <CustomInput
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email"
          showClearIcon={true}
          className="w-full mt-1"
        />
      </div>

      <div className="mb-6">
        <CustomInput
          type="password"
          id="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          placeholder="Password"
          showClearIcon={true}
          className="w-full mt-1"
        />
      </div>

      <CustomButton
        type="submit"
        variant="contained"
        color="primary"
        className="w-full"
        fontSize="16px"
      >
        Sign In
      </CustomButton>
    </form>
  );
};

export default SignInForm;
