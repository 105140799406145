import React, { useState } from "react";
import { MdWork, MdLocationOn, MdNote, MdPerson } from "react-icons/md";
import CustomInput from "../../components/CustomInput";
import StatusComponent from "../../components/StatusComponent";
import DatePickerComponent from "../../components/DatePickerComponent";
import { Project } from "../../types/Project";
import PeopleAssigner from "../PeopleAssigner/PeopleAssigner";

interface ProjectDetailsProps {
  project: Project;
  editableField: string | null;
  tempValue: string;
  setTempValue: (value: string) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  handleFieldClick: (fieldName: string, fieldValue: string) => void;
  handleFieldBlur: (fieldName: string) => void;
  handleDateChange: (date: Date) => void;
  handleNotesOpen: () => void;
  handleUpdateStatus: (newStatus: string) => void;
}

const ProjectDetailsContainer: React.FC<ProjectDetailsProps> = ({
  project,
  editableField,
  tempValue,
  setTempValue,
  inputRef,
  handleFieldClick,
  handleFieldBlur,
  handleDateChange,
  handleNotesOpen,
  handleUpdateStatus,
}) => {
  const [workType, setWorkType] = useState(project.workType || "");
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const renderCustomInput = (fieldName: string, fieldValue: string) => (
    <CustomInput
      id={`input-${fieldName}`}
      ref={inputRef}
      value={tempValue}
      onChange={(e) => setTempValue(e.target.value)}
      onBlur={() => handleFieldBlur(fieldName)}
      placeholder={fieldValue ? "" : "Enter value..."}
      className="w-full"
      showClearIcon={false}
      style={{ height: "44px" }}
    />
  );

  return (
    <div className="space-y-3 w-full">
      <StatusComponent
        status={project.projectStatus || "No Status"}
        onUpdateStatus={handleUpdateStatus}
      />

      <div className="flex items-center w-[100%]">
        <div
          className="w-full "
          onClick={() =>
            handleFieldClick(
              "projectName",
              project.projectName || "Unnamed Project"
            )
          }
        >
          {editableField === "projectName" ? (
            renderCustomInput(
              "projectName",
              project.projectName || "Unnamed Project"
            )
          ) : (
            <p
              className={`text-3xl font-semibold ${
                !isExpanded ? "truncate" : ""
              }`}
              onClick={handleExpandClick}
              style={{
                whiteSpace: isExpanded ? "normal" : "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {project.projectName || "Unnamed Project"}
            </p>
          )}
        </div>
      </div>
      <div className="flex items-center w-full">
        <MdWork className="text-xl mr-2" />
        <div
          className="w-[80%]"
          onClick={() =>
            handleFieldClick("workType", project.workType || "Add Work Type...")
          }
        >
          {editableField === "workType" ? (
            <div className="flex justify-around items-center">
              {["commercial", "residential"].map((type) => (
                <label key={type} className="flex items-center">
                  <input
                    type="radio"
                    name="workType"
                    checked={workType === type}
                    onChange={() => {
                      setWorkType(type);
                      setTempValue(type);
                      handleFieldBlur("workType");
                    }}
                    className="mr-2"
                  />{" "}
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </label>
              ))}
            </div>
          ) : (
            <p className="text-lg">{project.workType || "Add Work Type..."}</p>
          )}
        </div>
      </div>

      <div className="flex items-center w-full">
        <MdLocationOn className="text-xl mr-2" />
        <div
          className="w-[80%]"
          onClick={() =>
            handleFieldClick("address", project.address || "Add Address...")
          }
        >
          {editableField === "address" ? (
            renderCustomInput("address", project.address || "Add Address...")
          ) : (
            <p className="text-lg">{project.address || "Add Address..."}</p>
          )}
        </div>
      </div>

      <div className="flex items-center w-full" onClick={handleNotesOpen}>
        <MdNote className="text-xl mr-2" />
        <div className="w-full flex items-center">
          <p className="text-lg">
            {project.notes?.length ? "View Notes" : "Add notes..."}
          </p>
          {project.notes?.length ? (
            <div className="ml-2 flex items-center justify-center w-4 h-4 bg-gray-400 rounded-full text-xs text-white">
              {project.notes?.length}
            </div>
          ) : null}
        </div>
      </div>

      <div className="flex items-center w-full">
        <MdPerson className="text-xl mr-2" />
        <div
          className="w-[80%]"
          onClick={() =>
            handleFieldClick(
              "contractor",
              project.contractor || "Add Contractor..."
            )
          }
        >
          {editableField === "contractor" ? (
            renderCustomInput(
              "contractor",
              project.contractor || "Add Contractor..."
            )
          ) : (
            <p className="text-lg">
              {project.contractor || "Add Contractor..."}
            </p>
          )}
        </div>
      </div>

      <div className="flex items-center w-full">
        <DatePickerComponent
          dateReceived={project.dateReceived}
          onDateChange={handleDateChange}
        />
      </div>

      <div className="flex items-center text-base w-full mt-2">
        <PeopleAssigner />
      </div>
    </div>
  );
};

export default ProjectDetailsContainer;
