import React from "react";
import { IoArrowBack } from "react-icons/io5"; // Import the icon

interface ViewerHeaderProps {
  navigate: any;
  blueprintName: string;
}

const ViewerHeader: React.FC<ViewerHeaderProps> = ({ navigate, blueprintName }) => {
  return (
    <div className="sticky text-base top-0 z-50 bg-gray-200 px-4 py-4 flex justify-between items-center">
      <button onClick={() => navigate(-1)} className="flex items-center">
        <IoArrowBack size={20} /> {/* Left arrow icon */}
      </button>
      <div className="text-lg">{blueprintName}</div>
      <button className="p-1 w-fit bg-blue-500 text-white rounded">
        Set Scale
      </button>
    </div>
  );
};

export default ViewerHeader;
