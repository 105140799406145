// trade-forge is an app for creating projects that have paint estimates in them and can track the whole job start to fin, with detailed tooling!! react/TS/Tailwind CSS

// ok i need your help, firstly, havent worked on app in two months and im gonna feed you a buncha stuff, id like to re-learn how app is functioning on the frontend with backend, like when / what is saving to context or local storage, etc, when is stuff posted or called frfom firestore or storage???


import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

interface ProjectLimitDialogProps {
  open: boolean;
  onClose: () => void;
}

const ProjectLimitDialog: React.FC<ProjectLimitDialogProps> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Project Limit Reached</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You have reached the maximum limit of 5 active projects. Please complete or delete an existing project before starting a new one.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectLimitDialog;
