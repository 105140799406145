import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MdDateRange } from 'react-icons/md';
import Modal from './Modal';
import './date-picker-component.css';

interface DatePickerComponentProps {
  dateReceived?: string;
  onDateChange: (date: Date) => void;
}

const DatePickerComponent: React.FC<DatePickerComponentProps> = ({ dateReceived, onDateChange }) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(dateReceived ? new Date(dateReceived) : new Date());

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setSelectedDate(date);
      onDateChange(date);
      setShowDatePicker(false);
    }
  };

  return (
    <div className="flex items-center">
      <MdDateRange className="text-2xl mr-2 cursor-pointer" onClick={() => setShowDatePicker(true)} />
      <div className="cursor-pointer text-lg" onClick={() => setShowDatePicker(true)}>
        {selectedDate ? selectedDate.toLocaleDateString() : 'Click to add date'}
      </div>
      <Modal isOpen={showDatePicker} onClose={() => setShowDatePicker(false)} fullWidth>
        <div className="h-full">
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            inline
            calendarClassName="custom-calendar"
          />
        </div>
      </Modal>
    </div>
  );
};

export default DatePickerComponent;
