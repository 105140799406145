import React from "react";

const SignInHeader: React.FC = () => (
  <div className="text-center mt-10">
    <h1 className="text-2xl font-bold">Trade Forge</h1>
    <h1 className="mt-4">Built by painters, for painters.</h1>
  </div>
);

export default SignInHeader;
