import React, { ReactNode, useEffect } from 'react';
import { MdOutlineClose } from 'react-icons/md';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  children?: ReactNode;
  fullWidth?: boolean; // New prop to control full-width content
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, className, fullWidth }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  return (
    <div className={`fixed top-0 left-0 right-0 bottom-0 z-50 flex items-end justify-center ${isOpen ? 'block' : 'hidden'}`}>
      <div className="fixed top-0 left-0 right-0 bottom-0 bg-black opacity-50" onClick={onClose}></div>
      <div className={`bg-white w-full max-w-lg transform transition-transform duration-300 ease-in-out ${isOpen ? 'translate-y-0' : 'translate-y-full'} ${className}`} style={{ minHeight: '30%', maxHeight: '80%', overflowY: 'auto' }}>
        <div className="flex justify-between items-center px-4 py-2 bg-gray-100">
          <button className="text-gray-700" onClick={onClose}>
            <MdOutlineClose className="text-2xl" />
          </button>
        </div>
        <div className="border-t border-gray-300"></div>
        <div className={`h-full ${fullWidth ? 'p-0' : 'p-4'} pb-4`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
