import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import ViewerHeader from "./ViewerHeader";
import BlueprintViewer from "./BlueprintViewer";

const ViewBlueprintPage: React.FC = () => {
  const navigate = useNavigate();
  const { projectId, blueprintName } = useParams<{
    projectId: string;
    blueprintName: string;
  }>();

  if (!projectId || !blueprintName) {
    return <div>Error: Missing project ID or blueprint name</div>;
  }

  return (
    <div className="blueprint-page-container">
      <ViewerHeader navigate={navigate} blueprintName={blueprintName} />
      <BlueprintViewer projectId={projectId} blueprintName={blueprintName} />
    </div>
  );
};

export default ViewBlueprintPage;
