import React from "react";
import { Swatch } from "../../types/Project";

interface SwatchTileProps {
  swatch: Swatch;
  onClick: () => void;
}

const SwatchTile: React.FC<SwatchTileProps> = ({ swatch, onClick }) => {
  return (
    <div
      className="w-32 h-36 border rounded-lg flex-shrink-0 relative cursor-pointer"
      onClick={onClick}
    >
      <div className="absolute top-0 left-0 w-full h-2/3 bg-white bg-opacity-75 text-sm p-1 text-center truncate">
        <span className="text-base">{swatch.swCode}</span> <br />
        <span className="font-semibold">{swatch.name}</span> <br />
        <span className="block overflow-hidden text-ellipsis whitespace-normal leading-tight max-h-[2.5em]">
          {swatch.productType}
        </span>
      </div>
      <div
        className="absolute bottom-0 left-0 w-full h-1/3 flex items-center justify-between px-2"
        style={{ backgroundColor: swatch.hex }}
      >
        <div className="bg-white bg-opacity-75 rounded-full px-2 py-1 text-xs">
          {swatch.paintCode}
        </div>
      </div>
    </div>
  );
};

export default SwatchTile;
