import React from "react";
import { Pie, Bar, Line } from "react-chartjs-2";
import Page from "../../components/Page";
import CustomButton from "../../components/CustomButton";
import {
  Chart,
  ArcElement,
  BarElement,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Title,
} from "chart.js";
import paintSprayImage from "../../assets/paint-spray.jpg";
import AboutSection from "../../components/AboutSection/AboutSection";
import MissionSection from "../../components/MissionSection/MissionSection";
import WhyChooseUsSection from "../../components/WhyChooseUsSection/WhyChooseUsSection";
import ChartsSection from "../../components/ChartsSection/ChartsSection";
import PricingSection from "../../components/PricingSection/PricingSection";

Chart.register(
  ArcElement,
  BarElement,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Title
);

const AboutUsPage: React.FC = () => {
  return (
    <Page className="max-w-[1500px] ml-auto mr-auto">
      <div className="text-center mt-6 px-4 lg:px-0">
        <h1 className="sm:text-start sm:ml-[21%] sm:mt-[5%] text-3xl sm:text-5xl font-bold text-gray-800">
          About Trade Forge
        </h1>
        <AboutSection paintSprayImage={paintSprayImage} />
        <MissionSection />
        <WhyChooseUsSection />
        <ChartsSection />
        <PricingSection />
      </div>
    </Page>
  );
};

export default AboutUsPage;
