import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PhotoUploadButton from "../../../components/Photos/PhotoUploadButton";
import PhotosList from "../../../components/Photos/PhotoList";
import UploadModal from "../../../components/Photos/UploadModalComponent";
import ProjectNavigation from "../../../components/ProjectNavigation";
import { IoIosArrowBack } from "react-icons/io";

const PhotosPage: React.FC = () => {
  const navigate = useNavigate();
  const { projectId } = useParams<{ projectId: string }>();
  const [photos, setPhotos] = useState<{ file: File; name: string }[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setSelectedFile(file);
      setFileName(file.name);
      setIsModalOpen(true);
    }
  };

  const handleMobileUpload = () => {
    document.getElementById("file-upload")?.click();
  };

  const handleUploadConfirm = () => {
    if (selectedFile) {
      const newPhoto = { file: selectedFile, name: fileName };
      setPhotos((prevPhotos) => [...prevPhotos, newPhoto]);
      setSelectedFile(null);
      setFileName("");
      setIsModalOpen(false);
    }
  };

  return (
    <div className="relative min-h-screen h-[200rem]">
      <div className="sticky top-0 left-0 right-0 bg-white z-50">
        <div className="flex justify-between p-4">
          <IoIosArrowBack
            onClick={() => navigate(-1)}
            className="text-2xl cursor-pointer"
          />
          <p
            className="text-blue-500 text-xl cursor-pointer"
            onClick={() => navigate(`/project-home/${projectId}`)}
          >
            Back To Project
          </p>
        </div>
        <h1 className="text-3xl text-start ml-[2.5rem]">Photos</h1>
        <ProjectNavigation />
      </div>
      <div className="flex flex-col items-center ml-2 pt-1 space-y-4">
        <PhotoUploadButton onClick={handleMobileUpload} />
        <input
          id="file-upload"
          type="file"
          accept="image/jpeg, image/png, image/gif"
          multiple
          className="hidden"
          onChange={handleFileUpload}
        />
        <PhotosList photos={photos} />
      </div>
      <UploadModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        fileName={fileName}
        setFileName={setFileName}
        onConfirm={handleUploadConfirm}
      />
    </div>
  );
};

export default PhotosPage;
