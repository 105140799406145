import React, { useState, useEffect, useRef } from "react";
import { FaUserPlus } from "react-icons/fa";
import CustomInput from "../../components/CustomInput";

const PeopleAssigner: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPeople, setSelectedPeople] = useState<string[]>([]);
  const [customNames, setCustomNames] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [startY, setStartY] = useState<number | null>(null);

  const nameList = [
    "Hank Hexagon",
    "Bobby Rectangle",
    "Leo Toppington",
    "Bernard Soupster",
    "Travis Pastrami",
    "Marshall Teach",
  ];

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredNames = [...nameList, ...customNames].filter((name) =>
    name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleNameToggle = (name: string) => {
    setSelectedPeople((prev) =>
      prev.includes(name) ? prev.filter((p) => p !== name) : [...prev, name]
    );
    setShowUpdateButton(true);
  };

  const handleAddName = (name: string) => {
    if (!selectedPeople.includes(name)) {
      setSelectedPeople([...selectedPeople, name]);
    }
    if (!nameList.includes(name) && !customNames.includes(name)) {
      setCustomNames([...customNames, name]);
    }
    setShowUpdateButton(true);
  };

  const handleSelectAllToggle = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedPeople([...nameList, ...customNames]);
    } else {
      setSelectedPeople([]);
    }
    setShowUpdateButton(true);
  };

  const handleUpdate = () => {
    setIsModalOpen(false);
    setShowUpdateButton(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleTouchStart = (e: TouchEvent) => {
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e: TouchEvent) => {
    if (startY !== null && e.touches[0].clientY - startY > 100) {
      closeModal();
    }
  };

  useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      modalElement.addEventListener("touchstart", handleTouchStart);
      modalElement.addEventListener("touchmove", handleTouchMove);

      return () => {
        modalElement.removeEventListener("touchstart", handleTouchStart);
        modalElement.removeEventListener("touchmove", handleTouchMove);
      };
    }
  }, [startY]);

  return (
    <>
      <div className="flex items-center space-x-2 space-y-2 flex-wrap">
        <button
          onClick={() => setIsModalOpen(true)}
          className="flex items-center text-lg border-black border px-1  text-black rounded-full"
        >
          <FaUserPlus className="mr-2" />
          Assign
        </button>

        {/* Display selected people as pill buttons */}
        {selectedPeople.map((person, index) => (
          <span
            key={index}
            className="px-2 py-1 border border-gray-400 text-sm text-gray-700 rounded-full"
          >
            {person}
          </span>
        ))}
      </div>

      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-end"
          onClick={closeModal}
        >
          <div
            ref={modalRef}
            className="w-full h-[80%] bg-white rounded-t-lg p-4 relative"
            onClick={(e) => e.stopPropagation()}
          >
            <CustomInput
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search for a name..."
              showClearIcon={true}
              className="w-full mb-4"
            />

            <div className="flex items-center mb-2">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAllToggle}
                className="mr-2"
              />
              <span>Select All</span>
            </div>

            <div className="overflow-y-auto">
              {filteredNames.length > 0 ? (
                filteredNames.map((name, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between p-2 hover:bg-gray-200"
                  >
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={selectedPeople.includes(name)}
                        onChange={() => handleNameToggle(name)}
                        className="mr-2"
                      />
                      {name}
                    </label>
                    <button
                      className="px-2 py-1 bg-blue-500 text-white text-sm rounded"
                      onClick={() => handleAddName(name)}
                    >
                      Add
                    </button>
                  </div>
                ))
              ) : (
                <div className="p-2 text-gray-500">No match found.</div>
              )}
              {searchTerm &&
                !nameList.includes(searchTerm) &&
                !customNames.includes(searchTerm) && (
                  <div className="flex justify-between items-center p-2 bg-gray-100 mt-2">
                    <span className="text-gray-500">+ Add "{searchTerm}"</span>
                    <button
                      className="px-2 py-1 bg-green-500 text-white text-sm rounded"
                      onClick={() => handleAddName(searchTerm)}
                    >
                      Add
                    </button>
                  </div>
                )}
            </div>

            {showUpdateButton && (
              <button
                className="w-full mt-4 px-4 py-2 bg-blue-500 text-white text-center rounded"
                onClick={handleUpdate}
              >
                Update
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PeopleAssigner;
