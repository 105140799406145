import React from "react";

const RegisterHeader: React.FC = () => (
  <div className="text-center mt-10">
    <h1 className="text-2xl font-bold">Register</h1>
    <p className="text-sm text-gray-600 mt-2">Create a new account.</p>
  </div>
);

export default RegisterHeader;
