import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import React from "react";
import FloatingMenu from "./FloatingMenu";

interface BlueprintViewerProps {
  projectId: string;
  blueprintName: string;
}

const BlueprintViewer: React.FC<BlueprintViewerProps> = ({
  projectId,
  blueprintName,
}) => {
  return (
    <div className="blueprint-viewer-container">
  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">

        <div className="blueprint-viewer">
          <Viewer
            fileUrl={`https://firebasestorage.googleapis.com/v0/b/trade-forge.appspot.com/o/projects%2F${projectId}%2F${encodeURIComponent(
              blueprintName
            )}?alt=media`}
            defaultScale={SpecialZoomLevel.PageFit}
          />
        </div>
      </Worker>
      <FloatingMenu />
    </div>
  );
};

export default BlueprintViewer;
