import React from "react";
import { useNavigate } from "react-router-dom";
import ProjectHeader from "../../../components/ProjectHeader";
import AddressForm from "../../../components/AddressForm/AddressForm";
import { useProjects } from "../../../contexts/ProjectContext";

const ConfirmAddressPage: React.FC = () => {
  const navigate = useNavigate();
  const { inProgressProject, setInProgressProject } = useProjects();

  const handleConfirmDetails = (addressData: any) => {
    const { projectName, addressLine1, addressLine2, city, state, zip } = addressData;
    const fullAddress = `${addressLine1}${addressLine2 ? `, ${addressLine2}` : ""}, ${city}, ${state}, ${zip}`;
    const updatedProject = {
      ...inProgressProject,
      projectName,
      address: fullAddress,
    };
    setInProgressProject(updatedProject);
    console.log("Address confirmed and saved to context:", updatedProject);
    navigate("/project-details");
  };

  return (
    <div className="relative min-h-screen">
      <ProjectHeader
        title="Confirm Address"
        leftIcon="arrow"
        rightIcon="none"
        onLeftClick={() => navigate("/project-address")}
      />
      <AddressForm initialData={inProgressProject} onConfirm={handleConfirmDetails} />
    </div>
  );
};

export default ConfirmAddressPage;
