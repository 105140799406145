import React from 'react';

interface ComingSoonProps {
  category: string;
}

const ComingSoon: React.FC<ComingSoonProps> = ({ category }) => (
  <div className="mt-5 p-4 bg-gray-100 rounded-lg mx-4 max-w-[550px] ml-auto mr-auto">
    <h3 className="text-lg font-semibold text-center">{category}</h3>
    <p className="text-sm text-gray-500 mt-4">
      The {category} leaderboard is coming soon! This category will show the top users based on their {category.toLowerCase()}. Stay tuned for future updates!
    </p>
  </div>
);

export default ComingSoon;
