import React from "react";
import { CircularProgress } from "@mui/material";
import { AiOutlineCompass } from "react-icons/ai";
import CustomButton from "../CustomButton";
import CustomInput from "../CustomInput";

interface CreateAddressFormProps {
  address: string;
  handleAddressChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCurrentLocation: () => void;
  loading: boolean;
}

const CreateAddressForm: React.FC<CreateAddressFormProps> = ({
  address,
  handleAddressChange,
  handleCurrentLocation,
  loading,
}) => (
  <div className="absolute top-4 lg:mt-[1rem] ml-[1rem] left-4 right-4 z-20">
    <CustomInput
      value={address}
      onChange={handleAddressChange}
      placeholder="Enter project address"
      showClearIcon={true}
      className="w-full max-w-[550px] rounded-lg bg-white mb-2"
    />
    <div className="mt-2 absolute right-0">
      <CustomButton
        variant="contained"
        color="primary"
        onClick={handleCurrentLocation}
        className="h-[1.6rem]"
        fontSize="0.7rem"
        disabled={loading}
        icon={
          loading ? (
            <CircularProgress size={20} className="mr-1" />
          ) : (
            <AiOutlineCompass className="mr-1" />
          )
        }
        iconPosition="left"
      >
        {loading ? "Locating..." : "Use Current Location"}
      </CustomButton>
    </div>
  </div>
);

export default CreateAddressForm;
