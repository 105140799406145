import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import opencage from "opencage-api-client";
import { useProjects } from "../../../contexts/ProjectContext";
import ProjectHeader from "../../../components/ProjectHeader";
import CreateAddressForm from "../../../components/CreateAddress/CreateAddressForm";
import AddressDisplay from "../../../components/CreateAddress/AddressDisplay";
import mapImage from "../../../assets/map.png";

const OPEN_CAGE = "705626d865194d8f984ef2589d7cf2e9";

const ProjectAddressPage: React.FC = () => {
  const navigate = useNavigate();
  const { setInProgressProject } = useProjects();
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Clear the current project in context when navigating to this page
    setInProgressProject(null);
  }, [setInProgressProject]);

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
  };

  const handleCurrentLocation = () => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            const response = await opencage.geocode({
              q: `${latitude},${longitude}`,
              key: OPEN_CAGE,
            });
            if (response.results.length > 0) {
              const location = response.results[0];
              const components = location.components;

              const formattedAddress = `${
                components.house_number || ""
              } ${components.road || ""}`.trim();
              setAddress(formattedAddress);
              setCity(
                components.city || components.town || components.village || ""
              );
              setState(components.state || "");
              setPostalCode(components.postcode || "");
              console.log("Updated address using current location:", {
                address: formattedAddress,
                city:
                  components.city || components.town || components.village || "",
                state: components.state || "",
                postalCode: components.postcode || "",
              });
            } else {
              console.error("No results found for the given coordinates.");
            }
          } catch (error) {
            console.error("Error during reverse geocoding:", error);
          } finally {
            setLoading(false);
          }
        },
        (error) => {
          console.error("Error getting location: ", error);
          setLoading(false);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      setLoading(false);
    }
  };

  const handleConfirmAddress = () => {
    const fullAddress = `${address}, ${city}, ${state}, ${postalCode}`;
    setInProgressProject((prev: any) => ({
      ...prev,
      address: fullAddress,
    }));
    console.log("Address saved to context:", fullAddress);
    navigate("/confirm-address");
  };

  return (
    <div className="relative min-h-screen mt-3">
      <ProjectHeader
        title="Project Address"
        leftIcon="close"
        rightIcon="arrow"
        onLeftClick={() => navigate("/")}
        onRightClick={
          address ? handleConfirmAddress : () => navigate("/project-details")
        }
      />
      <div
        className="absolute top-[6rem] left-0 right-0 bottom-0 bg-gray-200"
        style={{
          backgroundImage: `url(${mapImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <CreateAddressForm
          address={address}
          handleAddressChange={handleAddressChange}
          handleCurrentLocation={handleCurrentLocation}
          loading={loading}
        />
        <AddressDisplay
          address={address}
          city={city}
          state={state}
          postalCode={postalCode}
          handleCancel={() => navigate("/")}
          handleConfirmAddress={handleConfirmAddress}
        />
      </div>
    </div>
  );
};

export default ProjectAddressPage;
