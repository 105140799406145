import React from 'react';
import { Project } from '../../types/Project';

interface ProjectItemProps {
  project: Project;
  onClick: (projectId: string) => void;
}

const ProjectItem: React.FC<ProjectItemProps> = ({ project, onClick }) => {
  return (
    <li
      key={project.id}
      className="flex items-center py-2 cursor-pointer text-lg space-y-2 hover:bg-gray-200 rounded"
      onClick={() => onClick(project.id)}
    >
      <div className="mr-2 mt-2">
        <svg className="w-4 h-4 text-blue-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2a1 1 0 002 0V7zm-1 6a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
        </svg>
      </div>
      <span>{project.projectName}</span>
    </li>
  );
};

export default ProjectItem;
