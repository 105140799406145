import React from 'react';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { SnackbarOrigin } from '@mui/material/Snackbar';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

interface SnackbarMessageProps {
  open: boolean;
  handleClose: (event: React.SyntheticEvent<any, Event> | Event, reason?: SnackbarCloseReason) => void;
  message: string;
  anchorOrigin?: SnackbarOrigin;
}

const SnackbarMessage: React.FC<SnackbarMessageProps> = ({
  open,
  handleClose,
  message,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
}) => {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={anchorOrigin}>
      <Alert className='mb-[2.5%]' onClose={(event) => handleClose(event, undefined)} severity="info" sx={{ width: '95%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarMessage;
