import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Page from "../../components/Page";
import { registerUser } from "../../services/loginFunctions";
import RegisterHeader from "../../components/Register/RegisterHeader";
import RegisterErrorMessage from "../../components/Register/RegisterErrorMessage";
import RegisterForm from "../../components/Register/RegisterForm";

const RegisterPage: React.FC = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleRegistration = async (formData: any) => {
    setError("");

    try {
      await registerUser(formData);
      navigate("/verify-email");
    } catch (error) {
      console.error("Registration error:", error);
      setError(
        (error as { message?: string }).message || "Failed to register."
      );
    }
  };

  return (
    <Page className="mx-4">
      <RegisterHeader />
      <RegisterErrorMessage error={error} />
      <RegisterForm onRegister={handleRegistration} />
      <p className=" text-base mt-8 text-gray-600">
        Already have an account?{" "}
        <Link to="/signin" className="text-blue-500 hover:text-blue-700">
          Click here to sign in
        </Link>
      </p>
    </Page>
  );
};

export default RegisterPage;
