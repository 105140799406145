import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

interface AboutSectionProps {
  paintSprayImage: string;
}

const AboutSection: React.FC<AboutSectionProps> = ({ paintSprayImage }) => {
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    const loadImage = async () => {
      const cachedImage = localStorage.getItem("paintSprayImage");
      if (cachedImage) {
        setImageSrc(cachedImage);
      } else {
        const image = new Image();
        image.src = paintSprayImage;
        image.onload = () => {
          localStorage.setItem("paintSprayImage", paintSprayImage);
          setImageSrc(paintSprayImage);
        };
      }
    };

    loadImage();
  }, [paintSprayImage]);

  return (
    <div className="sm:mx-[18%] flex flex-col sm:flex-row justify-center items-center">
      <p className="mt-4 text-[.9rem] sm:text-xl text-gray-600 text-start sm:mt-auto sm:mb-auto sm:w-1/2">
        At Trade Forge, we empower painting businesses by providing innovative
        solutions that streamline operations and enhance project management.
        Our tools are designed to help business owners and estimators thrive by
        increasing efficiency, accuracy, and profitability.
      </p>
      <div className="mt-6 sm:ml-4 flex justify-center items-center w-full sm:w-auto" style={{ maxWidth: "350px", height: "150px" }}>
        {imageSrc ? (
          <img
            src={imageSrc}
            alt="Paint Spray"
            className="w-full h-full object-cover"
          />
        ) : (
          <CircularProgress />
        )}
      </div>
    </div>
  );
};

export default AboutSection;
