import React from 'react';
import { useNavigate } from 'react-router-dom';
import ProjectItem from '../../ProjectItem/ProjectItem';
import { Project } from '../../../types/Project';

interface ProjectListProps {
  projects: Project[];
  onProjectClick: (projectId: string) => void;
}

const ProjectList: React.FC<ProjectListProps> = ({ projects, onProjectClick }) => {
  const navigate = useNavigate();

  return (
    <ul className="list-none pl-4">
      {projects.length > 0 ? (
        projects.map((project) => (
          <ProjectItem key={project.id} project={project} onClick={() => onProjectClick(project.id)} />
        ))
      ) : (
        <li
          className="flex items-center py-2 cursor-pointer hover:bg-gray-200 rounded"
          onClick={() => navigate('/project-address')}
        >
          <svg className="w-4 h-4 text-blue-500 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2a1 1 0 002 0V7zm-1 6a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
          </svg>
          <span className="text-sm">
            Click to <span className="text-blue-600 underline">Create your first project today!</span>
          </span>
        </li>
      )}
    </ul>
  );
};

export default ProjectList;
