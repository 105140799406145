import React from "react";

interface SignInMessageProps {
  message: string;
}

const SignInMessage: React.FC<SignInMessageProps> = ({ message }) => {
  return message ? <p className="text-green-500 mb-4">{message}</p> : null;
};

export default SignInMessage;
