import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { auth, onAuthStateChanged } from '../services/firebase-config';
import { User } from 'firebase/auth';

interface AuthContextType {
  currentUser: User | null;
  userData: any;
  setUserData: (data: any) => void;
  lastSavedProject: any;
  setLastSavedProject: (project: any) => void;
  activeJobs: any[];
  setActiveJobs: React.Dispatch<React.SetStateAction<any[]>>;
  currentProject: any;
  setCurrentProject: (project: any) => void;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userData, setUserData] = useState<any>(null);
  const [lastSavedProject, setLastSavedProject] = useState<any>(null);
  const [activeJobs, setActiveJobs] = useState<any[]>([]);
  const [currentProject, setCurrentProject] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      if (user) {
        try {
          const userData = { userName: 'testUser' }; // Placeholder data
          setUserData(userData);
          console.log("Fetched user data from DB:", userData);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } else {
        setUserData(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{
      currentUser,
      userData,
      setUserData,
      lastSavedProject,
      setLastSavedProject,
      activeJobs,
      setActiveJobs,
      currentProject,
      setCurrentProject
    }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextType {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
