import React, { useState, useEffect } from "react";
import Page from "../../components/Page";
import { db } from "../../services/firebase-config";
import { collection, getDocs, orderBy, query, limit } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import TopUsersList from "../../components/Leaderboard/TopUserList";
import UserModal from "../../components/Leaderboard/UserModal";
import ComingSoon from "../../components/Leaderboard/ComingSoon";
import PillSelector from "../../components/Leaderboard/PillSelector";
import SnackbarMessage from "../../components/SnackBarMessage";
import { User } from "../../types/User";

const LeaderboardPage: React.FC = () => {
  const [topUsers, setTopUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedPill, setSelectedPill] = useState("Estimates Created");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTopUsers = async () => {
      const usersRef = collection(db, "users");
      const q = query(usersRef, orderBy("totalBidsCreated", "desc"), limit(5));
      const querySnapshot = await getDocs(q);

      const users = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as User[];

      setTopUsers(users);
    };

    fetchTopUsers();
  }, []);

  const handleUserClick = (user: User) => {
    setSelectedUser(user);
  };

  const handleCloseDialog = () => {
    setSelectedUser(null);
  };

  const handlePillClick = (pill: string) => {
    setSelectedPill(pill);
    if (pill !== "Estimates Created") {
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Page className="mx-4">
      <div className="text-center mt-6 relative">
        <h1 className="text-3xl font-bold">Estimator Leaderboard</h1>
        <AiOutlineClose
          onClick={() => navigate(-1)}
          className="text-2xl -mt-4 p-1 bg-gray-200 rounded-full cursor-pointer absolute top-0 right-0"
        />
      </div>

      <PillSelector selectedPill={selectedPill} onPillClick={handlePillClick} />

      {selectedPill === "Estimates Created" ? (
        <TopUsersList topUsers={topUsers} onUserClick={handleUserClick} />
      ) : (
        <ComingSoon category={selectedPill} />
      )}

      <UserModal user={selectedUser} topUsers={topUsers} onClose={handleCloseDialog} />

      <SnackbarMessage
        open={snackbarOpen}
        handleClose={handleCloseSnackbar}
        message="Coming Soon!"
      />
    </Page>
  );
};

export default LeaderboardPage;
