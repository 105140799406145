import React from "react";

const WhyChooseUsSection: React.FC = () => {
  return (
    <div className="mt-8 px-3 py-4 bg-white mx-auto max-w-4xl text-center rounded-lg shadow">
      <h2 className="text-xl font-semibold">Why Choose Trade Forge?</h2>
      <p className="mt-2">Our platform offers:</p>
      <ul className="list-disc list-inside mt-4 text-left text-lg text-gray-700 space-y-2">
        <li className="hover:shadow-lg transform hover:scale-105 transition duration-300 ease-in-out">
          Increased efficiency in creating accurate estimates
        </li>
        <li className="hover:shadow-lg transform hover:scale-105 transition duration-300 ease-in-out">
          Improved accuracy of pricing for projects
        </li>
        <li className="hover:shadow-lg transform hover:scale-105 transition duration-300 ease-in-out">
          Comprehensive project management tools
        </li>
        <li className="hover:shadow-lg transform hover:scale-105 transition duration-300 ease-in-out">
          Streamlined operations for better productivity
        </li>
        <li className="hover:shadow-lg transform hover:scale-105 transition duration-300 ease-in-out">
          Enhanced decision-making capabilities
        </li>
        <li className="hover:shadow-lg transform hover:scale-105 transition duration-300 ease-in-out">
          Significant cost savings by potentially eliminating the need for
          an estimator's salary
        </li>
      </ul>
    </div>
  );
};

export default WhyChooseUsSection;
