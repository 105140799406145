import { initializeApp, FirebaseApp } from "firebase/app";
import { getAuth, Auth, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Define the structure of your config object explicitly
const firebaseConfig = {
  apiKey: "AIzaSyDt-Anrk92eC18QKbi5UdOgG-yoSXG4dlk",
  authDomain: "trade-forge.firebaseapp.com",
  projectId: "trade-forge",
  storageBucket: "trade-forge.appspot.com",
  messagingSenderId: "471144950819",
  appId: "1:471144950819:web:3ed5af43ab3ee8304c9596",
};

const app: FirebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth: Auth = getAuth(app);
const storage = getStorage(app);

export { app, auth, db, storage, onAuthStateChanged };
