import React, { useState, useEffect, useRef } from "react";
import { format } from "date-fns";
import { Menu, MenuItem, IconButton } from "@mui/material";
import { AiOutlineEllipsis, AiOutlineArrowDown } from "react-icons/ai";
import { doc, updateDoc, arrayUnion, arrayRemove, getDoc } from "firebase/firestore";
import { db } from "../services/firebase-config";
import { useAuth } from "../contexts/AuthContext";
import CustomButton from "../components/CustomButton";
import Modal from "../components/Modal";
import { Note } from "../types/Project"; 

interface NoteGeneratorProps {
  projectId: string;
  notes: Array<Note>;
  setNotes: React.Dispatch<React.SetStateAction<Array<Note>>>;
  open: boolean;
  onClose: () => void;
}

const MAX_CHAR_LIMIT = 1000; // Character limit for notes
const COOLDOWN_PERIOD = 5000; // Cooldown period between posts in milliseconds

const NoteGenerator: React.FC<NoteGeneratorProps> = ({ projectId, notes, setNotes, open, onClose }) => {
  const [newNote, setNewNote] = useState("");
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [lastPostTime, setLastPostTime] = useState<number>(0);
  const { userData } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedNote, setSelectedNote] = useState<Note | null>(null);
  const [expandedNotes, setExpandedNotes] = useState<Set<number>>(new Set());
  const [showAllNotes, setShowAllNotes] = useState(false);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);
  const scrollRef = useRef<HTMLDivElement>(null);

  const fetchNotes = async () => {
    const projectDoc = await getDoc(doc(db, "projects", projectId));
    if (projectDoc.exists()) {
      const projectData = projectDoc.data();
      setNotes(projectData?.notes || []);
    }
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  useEffect(() => {
    if (open && scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      setIsScrolledToBottom(true);
    }
  }, [open, notes]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const isBottomNoteVisible = (element: HTMLElement) => {
          const rect = element.getBoundingClientRect();
          return rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
        };

        const bottomNote = scrollRef.current?.lastElementChild;
        const bottom = scrollRef.current.scrollHeight - scrollRef.current.scrollTop <= scrollRef.current.clientHeight + 10; // Adjust this value if necessary

        if (bottomNote && isBottomNoteVisible(bottomNote as HTMLElement)) {
          setIsScrolledToBottom(true);
        } else {
          setIsScrolledToBottom(false);
        }
      }
    };

    const currentScrollRef = scrollRef.current;
    currentScrollRef?.addEventListener("scroll", handleScroll);
    return () => currentScrollRef?.removeEventListener("scroll", handleScroll);
  }, []);

  const handleAddNote = async () => {
    const currentTime = Date.now();
    if (currentTime - lastPostTime < COOLDOWN_PERIOD) {
      console.log("Please wait before posting another note.");
      return;
    }

    if (newNote.trim() === "" || newNote.length > MAX_CHAR_LIMIT) return;
    const date = format(new Date(), "dd MMM, yyyy @ hh:mm");
    const note: Note = {
      date,
      user: userData?.firstName || "Unknown",
      content: newNote,
    };
    await updateDoc(doc(db, "projects", projectId), {
      notes: arrayUnion(note),
    });
    setNewNote("");
    setUnsavedChanges(false);
    setLastPostTime(currentTime);
    fetchNotes();
  };

  const handleAddNoteAndClose = async () => {
    if (newNote.trim() !== "" && newNote.length <= MAX_CHAR_LIMIT) {
      await handleAddNote();
    }
    onClose();
  };

  const handleDeleteNote = async (note: Note) => {
    await updateDoc(doc(db, "projects", projectId), {
      notes: arrayRemove(note),
    });
    fetchNotes(); // Fetch the latest notes
    handleMenuClose();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewNote(e.target.value);
    setUnsavedChanges(true);
  };

  const handleCancel = () => {
    if (unsavedChanges) {
      // Handle unsaved changes if necessary
    } else {
      onClose();
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, note: Note) => {
    setAnchorEl(event.currentTarget);
    setSelectedNote(note);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedNote(null);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddNote();
    }
  };

  const toggleExpandNote = (index: number) => {
    setExpandedNotes((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  };

  const handleShowAllNotes = () => {
    setShowAllNotes(true);
  };

  const handleHideOlderNotes = () => {
    setShowAllNotes(false);
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      setIsScrolledToBottom(true);
    }
  };

  const handleScrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      setIsScrolledToBottom(true);
    }
  };

  const renderNotes = () => {
    const notesToDisplay = showAllNotes ? notes : notes.slice(-5);
    return notesToDisplay.map((note, index) => (
      <div key={index} className="mb-2 space-y-2">
        <div data-index={index.toString()}>
          <div className="flex justify-between items-center">
            <p className="text-sm font-semibold">{note.user}</p>
            <p className="text-xs text-gray-500">{note.date}</p>
            {note.user === userData?.firstName && (
              <IconButton onClick={(event) => handleMenuOpen(event, note)}>
                <AiOutlineEllipsis />
              </IconButton>
            )}
          </div>
          <div className={`overflow-hidden ${expandedNotes.has(index) ? "" : "max-h-[64px]"}`}>
            <p className="break-words">{note.content}</p>
          </div>
          {note.content.length > 100 && (
            <button className="text-blue-500" onClick={() => toggleExpandNote(index)}>
              {expandedNotes.has(index) ? "Show less" : "Show more"}
            </button>
          )}
          <div className="border-t border-gray-200 mt-2"></div> {/* Add divider */}
        </div>
      </div>
    ));
  };

  return (
    <Modal isOpen={open} onClose={handleCancel} className="h-[60vh]">
      <div className="flex flex-col w-full h-full">
        <div ref={scrollRef} className="flex-1 overflow-y-auto mb-32 px-4">
          {!showAllNotes && notes.length > 5 && (
            <button className="text-blue-500 mb-4" onClick={handleShowAllNotes}>
              Show older notes
            </button>
          )}
          {showAllNotes && (
            <button className="text-blue-500 mb-4" onClick={handleHideOlderNotes}>
              Hide older notes
            </button>
          )}
          {renderNotes()}
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            <MenuItem onClick={() => handleDeleteNote(selectedNote!)}>
              Delete
            </MenuItem>
          </Menu>
        </div>
        {!isScrolledToBottom && (
          <div
            className="absolute left-[46%] ml-auto mr-auto transform -translate-x-1/2 bottom-20 cursor-pointer bg-gray-300 text-black p-2 rounded-full animate-bounce"
            onClick={handleScrollToBottom}
          >
            <AiOutlineArrowDown />
          </div>
        )}
        <div className="flex items-center space-x-2 p-2 bg-white absolute bottom-0 left-0 w-full mb-12">
          <textarea
            value={newNote}
            onChange={handleInputChange}
            placeholder="New Note"
            className="flex-1 resize-none p-2 border rounded-md"
            onKeyDown={handleKeyDown}
            rows={1}
            style={{ minHeight: "48px", maxHeight: "150px", overflowY: "auto" }}
          />
          <CustomButton onClick={handleAddNote} color="primary" variant="contained">
            Post
          </CustomButton>
        </div>
        {newNote.length > MAX_CHAR_LIMIT && (
          <div className="text-red-500 text-sm mt-2">
            Note exceeds maximum length of {MAX_CHAR_LIMIT} characters.
          </div>
        )}
      </div>
    </Modal>
  );
};

export default NoteGenerator;
