import React, { ReactNode } from "react";

interface PageProps {
  children: ReactNode;
  className?: string;
}

const Page: React.FC<PageProps> = ({ children, className = "" }) => {
  return <div className={`pt-[4rem] px-0.5 sm:px-4 ${className}`}>{children}</div>;
};

export default Page;
