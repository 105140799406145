import React from 'react';

interface PhotosListProps {
  photos: { file: File; name: string }[];
}

const PhotosList: React.FC<PhotosListProps> = ({ photos }) => (
  <div className="w-full max-w-2xl mt-4">
    {photos.length === 0 ? (
      <p className="text-center text-gray-500">
        Add photos to get started!
      </p>
    ) : (
      <ul className="space-y-2 pr-2">
        {photos.map((photo, index) => (
          <li
            key={index}
            className="flex items-center justify-between p-2 border rounded-md"
          >
            <span className="text-gray-700">{photo.name}</span>
            <a
              href={URL.createObjectURL(photo.file)}
              download
              className="text-blue-500 hover:underline"
            >
              Download
            </a>
          </li>
        ))}
      </ul>
    )}
  </div>
);

export default PhotosList;
