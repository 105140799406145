import React, { useEffect, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";


const ProjectNavigation: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId } = useParams();
  const navContainerRef = useRef<HTMLDivElement>(null);
  const activeItemRef = useRef<HTMLDivElement>(null);

  const navItems = [
    { name: "ESTIMATE", path: `/create-estimate/${projectId}` },
    { name: "BLUEPRINTS", path: `/blueprints/${projectId}` },
    { name: "PHOTOS", path: `/photos/${projectId}` },
    { name: "MATERIALS", path: `/finish/${projectId}` },
  ];

  useEffect(() => {
    if (activeItemRef.current && navContainerRef.current) {
      const containerWidth = navContainerRef.current.offsetWidth;
      const itemLeft = activeItemRef.current.offsetLeft;
      const itemWidth = activeItemRef.current.offsetWidth;
      const scrollLeft = itemLeft - (containerWidth / 2) + (itemWidth / 2);
      navContainerRef.current.scrollTo({ left: scrollLeft, behavior: "smooth" });
    }
  }, [location.pathname]);

  return (
    <div
      ref={navContainerRef}
      className="overflow-x-auto whitespace-nowrap mt-4 border-t border-b border-gray-300 custom-scrollbar"
    >
      <div className="inline-flex space-x-4 px-4">
        {navItems.map((item) => (
          <div
            key={item.name}
            ref={location.pathname === item.path ? activeItemRef : null}
            className={`flex-none text-center py-2 cursor-pointer text-xl mb-1 ${
              location.pathname === item.path
                ? "border-b-4 border-blue-500 text-blue-500"
                : "border-b-4 border-transparent text-gray-500"
            }`}
            onClick={() => navigate(item.path)}
          >
            <p>{item.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectNavigation;
