import { createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { auth, db, storage } from "./firebase-config";
import { doc, setDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

interface RegisterFormData {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  companyName: string;
  jobTitle: string;
  birthday: string;
}

export const registerUser = async (formData: RegisterFormData) => {
  const {
    email,
    password,
    confirmPassword,
    firstName,
    lastName,
    companyName,
    jobTitle,
    birthday,
  } = formData;

  if (!email || !password || !confirmPassword) {
    throw new Error("Please fill in all fields.");
  }

  if (password !== confirmPassword) {
    throw new Error("Passwords don't match!");
  }

  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Send email verification
    await sendEmailVerification(user);

    // Save additional user info to Firestore
    await setDoc(doc(db, "users", user.uid), {
      firstName: firstName || "",
      lastName: lastName || "",
      companyName: companyName || "",
      jobTitle: jobTitle || "",
      birthday: birthday || "",
      email: email,
      createdAt: new Date(),
      totalBidsCreated: 0,
    });

    console.log("Registered with:", user);
  } catch (error) {
    console.error("Registration error:", error);
    throw new Error((error as { message?: string }).message || "Failed to register.");
  }
};

interface LoginFormData {
  email: string;
  password: string;
}

export const loginUser = async (formData: LoginFormData) => {
  const { email, password } = formData;

  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (error) {
    console.error("Failed to sign in:", error);
    throw new Error((error as { message?: string }).message || "Failed to sign in.");
  }
};

export const resendVerificationEmail = async () => {
  if (auth.currentUser) {
    await sendEmailVerification(auth.currentUser);
  } else {
    throw new Error("No user is currently logged in.");
  }
};

export const checkEmailVerified = (navigate: any) => {
  onAuthStateChanged(auth, (user) => {
    if (user && user.emailVerified) {
      navigate("/signin?verified=true");
    }
  });
};

// Function to upload profile picture
export const uploadProfilePicture = async (userId: string, file: File, setUploadProgress: (progress: number) => void) => {
  const storageRef = ref(storage, `users/${userId}/profile.jpg`);
  const uploadTask = uploadBytesResumable(storageRef, file);

  return new Promise<string>((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        console.error("Upload failed", error);
        reject(error);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        resolve(downloadURL);
      }
    );
  });
};
