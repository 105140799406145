import React from "react";
import CustomButton from "../CustomButton";

interface AddressDisplayProps {
  address: string;
  city: string;
  state: string;
  postalCode: string;
  handleCancel: () => void;
  handleConfirmAddress: () => void;
}

const AddressDisplay: React.FC<AddressDisplayProps> = ({ address, city, state, postalCode, handleCancel, handleConfirmAddress }) => (
  <div className="absolute bottom-5 left-4 right-4 z-10 p-4 max-w-[900px] ml-auto mr-auto">
    <div className="bg-white p-4 rounded-md shadow">
      <p className="font-semibold text-lg">{address}</p>
      <p>{city}, {state}</p>
      <p>{postalCode}</p>
      <div className="flex justify-between mt-4">
        <CustomButton
          variant="contained"
          color="secondary"
          onClick={handleCancel}
          className="w-[48%] h-[1.6rem]"
        >
          Cancel
        </CustomButton>
        <CustomButton
          variant="contained"
          color="primary"
          onClick={handleConfirmAddress}
          className="w-[48%] h-[1.6rem]"
        >
          Confirm
        </CustomButton>
      </div>
    </div>
  </div>
);

export default AddressDisplay;
