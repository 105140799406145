import React from 'react';
import { CircularProgress } from '@mui/material';
import { FaPencilAlt } from 'react-icons/fa';
interface ProfileHeaderProps {
  userData: any;
  loading: boolean;
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string; // Add className as an optional prop
}


const ProfileHeader: React.FC<ProfileHeaderProps> = ({ userData, loading, handleFileChange, className }) => {
  return (
    <div className={`flex items-start justify-start mt-8 ml-3 md:w-full md:flex-col ${className}`}>
    <div className="relative w-32 h-32 md:w-48 md:h-48">
      {/* Profile picture with larger size on medium screens */}
      {loading ? (
        <div className="w-32 h-32 md:w-48 md:h-48 rounded-full bg-gray-200 flex items-center justify-center">
          <CircularProgress />
        </div>
      ) : (
        <img
          src={userData?.profilePic || "https://via.placeholder.com/150"}
          alt="Profile"
          className="w-32 h-32 md:w-48 md:h-48 rounded-full object-cover"
          onClick={() => document.getElementById("file-input")?.click()}
        />
      )}
      {/* Pencil icon stays the same */}
    </div>
    <div className="text-left ml-4 mt-1 md:ml-0 md:mt-4">
      <h1 className="text-2xl md:text-3xl font-semibold">
        Hello, {userData?.firstName || userData?.email || "User"}
      </h1>
      <p className="text-base mt-1 text-gray-600">
        You joined {userData?.createdAt?.toDate().toLocaleDateString() || "N/A"}
      </p>
    </div>
  </div>

  );
};

export default ProfileHeader;
