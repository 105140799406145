// when im not toEditorSettings, these are all saying N/A when it should have the current users data displayed


import React from 'react';
import CustomInput from '../../components/CustomInput';
import CustomButton from '../../components/CustomButton';
import { FaPencilAlt } from 'react-icons/fa';

interface UserInfoFormProps {
  formData: any;
  isEditing: boolean;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCancelEdit: () => void;
  handleSaveChanges: () => void;
  handleEditClick: () => void;
}

const UserInfoForm: React.FC<UserInfoFormProps> = ({
  formData,
  isEditing,
  handleInputChange,
  handleCancelEdit,
  handleSaveChanges,
  handleEditClick
}) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow text-center relative w-full">
      <h2 className="text-lg font-semibold text-left">User Info</h2>
      {isEditing ? (
        <div className="space-y-5 text-left">
          <CustomInput
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            placeholder="First Name"
            className="w-full mb-2"
          />
          <CustomInput
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            placeholder="Last Name"
            className="w-full mb-2"
          />
          <CustomInput
            type="text"
            name="companyName"
            value={formData.companyName}
            onChange={handleInputChange}
            placeholder="Company Name"
            className="w-full mb-2"
          />
          <CustomInput
            type="text"
            name="jobTitle"
            value={formData.jobTitle}
            onChange={handleInputChange}
            placeholder="Job Title"
            className="w-full mb-2"
          />
          <CustomInput
            type="date"
            name="birthday"
            value={formData.birthday}
            onChange={handleInputChange}
            placeholder="Birthday"
            className="w-full mb-2"
          />
          <div className="flex justify-between mt-4">
            <CustomButton
              onClick={handleCancelEdit}
              variant="contained"
              color="error"
              className="w-[48%]"
              fontSize="0.6rem"
            >
              Cancel
            </CustomButton>
            <CustomButton
              onClick={handleSaveChanges}
              variant="contained"
              color="primary"
              className="w-[48%]"
              fontSize="0.6rem"
            >
              Save Changes
            </CustomButton>
          </div>
        </div>
      ) : (
        <div className="text-left">
          <p className="text-sm text-gray-500 ">
            Company: {formData.companyName || "N/A"}
          </p>
          <p className="text-sm text-gray-500">
            Position: {formData.jobTitle || "N/A"}
          </p>
          <p className="text-sm text-gray-500">Email: {formData.email}</p>
          <p className="text-sm text-gray-500">
            Birthday: {formData.birthday || "N/A"}
          </p>
          <button
            className="absolute top-0 right-0 -mt-3 -mr-3 m-2 text-gray-600 hover:text-gray-800 rounded-full p-2 bg-white border border-gray-300 shadow"
            onClick={handleEditClick}
          >
            <FaPencilAlt />
          </button>
        </div>
      )}
    </div>
  );
};

export default UserInfoForm;
