import React, { useState } from "react";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";

interface RegisterFormProps {
  onRegister: (formData: any) => void;
}

const RegisterForm: React.FC<RegisterFormProps> = ({ onRegister }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    companyName: "",
    jobTitle: "",
    birthday: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onRegister(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-md mt-10 mx-auto">
      <div className="flex space-x-2 justify-between">
        <div className="mb-6 w-full">
          <CustomInput
            type="text"
            id="first-name"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            showClearIcon={true}
            className="w-full mt-1"
            placeholder="First name"
          />
        </div>

        <div className="mb-6 w-full">
          <CustomInput
            type="text"
            id="last-name"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            showClearIcon={true}
            className="w-full mt-1"
            placeholder="Last name"
          />
        </div>
      </div>

      <div className="mb-6">
        <CustomInput
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Company Email"
          showClearIcon={true}
          className="w-full mt-1"
        />
      </div>

      <div className="mb-6">
        <CustomInput
          type="password"
          id="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          showClearIcon={true}
          className="w-full mt-1"
          placeholder="Password"
        />
      </div>

      <div className="mb-6 ">
        <CustomInput
          type="password"
          id="confirm-password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          showClearIcon={true}
          className="w-full mt-1"
          placeholder="Confirm Password"
        />
      </div>

      <div className="mb-6">
        <CustomInput
          type="text"
          id="company-name"
          name="companyName"
          value={formData.companyName}
          onChange={handleChange}
          showClearIcon={true}
          className="w-full mt-1"
          placeholder="Company Name"
        />
      </div>

      <div className="mb-6">
        <CustomInput
          type="text"
          id="job-title"
          name="jobTitle"
          value={formData.jobTitle}
          onChange={handleChange}
          showClearIcon={true}
          className="w-full mt-1"
          placeholder="Job Title"
        />
      </div>

      <div className="mb-6">
        <CustomInput
          type="date"
          id="birthday"
          name="birthday"
          value={formData.birthday}
          onChange={handleChange}
          showClearIcon={true}
          className="w-full mt-1"
          placeholder="Birthday"
        />
      </div>

      <CustomButton
        type="submit"
        variant="contained"
        color="primary"
        className="w-full"
        fontSize="16px"
      >
        Register
      </CustomButton>
    </form>
  );
};

export default RegisterForm;
