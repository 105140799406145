import React from "react";
import { AiOutlineClose, AiOutlineStar, AiFillStar, AiOutlineEllipsis } from "react-icons/ai";
import { MdOutlineIosShare, MdDeleteOutline } from "react-icons/md";
import { IconButton, Menu, MenuItem } from "@mui/material";

interface HeaderSectionProps {
  navigate: (path: string) => void;
  handleMenuOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleStarClick: () => void;
  isStarred: boolean;
  anchorEl: HTMLElement | null;
  handleMenuClose: () => void;
  handleDialogOpen: () => void;
}

const HeaderSection: React.FC<HeaderSectionProps> = ({
  navigate,
  handleMenuOpen,
  handleStarClick,
  isStarred,
  anchorEl,
  handleMenuClose,
  handleDialogOpen,
}) => (
  <div className="absolute top-0 left-0 right-0 z-10">
    <div className="flex justify-between pt-4 px-2 items-center">
      <AiOutlineClose onClick={() => navigate("/")} className="text-2xl cursor-pointer text-gray-500" />
      <div className="flex justify-center content-center space-x-1 text-gray-400">
        <div onClick={handleStarClick} className="cursor-pointer mx-2 mt-auto mb-auto">
          {isStarred ? (
            <AiFillStar className="text-2xl text-yellow-400 transition-colors duration-300" />
          ) : (
            <AiOutlineStar className="text-2xl" />
          )}
        </div>
        <MdOutlineIosShare className="text-2xl cursor-pointer mx-2 mt-auto mb-auto" />
        <IconButton onClick={handleMenuOpen} className=" ">
          <AiOutlineEllipsis className="text-2xl cursor-pointer mr-2" />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={handleDialogOpen}>
            <MdDeleteOutline className="mr-2" />
            Delete
          </MenuItem>
        </Menu>
      </div>
    </div>
  </div>
);

export default HeaderSection;
